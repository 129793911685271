import { useId, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Params, useNavigation } from "react-router-dom";

import { Button, ButtonGroup, ButtonProps } from "@new-black/lyra";
import { z } from "zod";

import { DefinedRouteButtonLink } from "components/routing";
import { DeleteModal } from "components/suite-composite/confirmation-modal/delete-modal";
import { IRouteDefinition } from "types/route-definitions";

export type ActionSubmitFooterProps = {
  disableDelete?: boolean;
  disableSave?: boolean;
  showLoadingState?: boolean;
  messages?: {
    deleteButtonMessage?: string;
    cancelButtonMessage?: string;
    saveButtonMessage?: string;
  };
  buttonVariants?: Partial<Record<"cancel" | "save", ButtonProps["variant"]>>;
  hideButtons?: Partial<Record<"save" | "delete", boolean>>;
  cancelRouteDefinition?: IRouteDefinition;
  cancelRouteDefinitionParams?: Params<string> | undefined;
  /** Can be used to pass the search params along to the next route */
  keepSearchParams?: boolean;
  deleteActionUrl?: string;
  formId: string;
  onCancel?: () => void;
  variant?: "material" | "lyra";
  saveButtonType?: ButtonProps["type"];
  onSavePress?: () => void;
};

/**
 * Component you can use in a sticky footer to provide a delete, cancel and save button that will submit the form to an action.
 * The actions can be found under intents. The delete action is called "delete" and the save action is called "save".
 * Just extend your form zod schema with `intent: z.enum(["save", "delete"])` and you are good to go.
 * It's nicer to do this in the file where your action lives.
 * @param props ActionSubmitFooterProps
 * @returns
 */
export function ActionSubmitFooter(props: ActionSubmitFooterProps) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { state } = useNavigation();

  const deleteFormId = useId();

  const {
    cancelRouteDefinition,
    cancelRouteDefinitionParams,
    disableDelete,
    disableSave,
    formId,
    keepSearchParams,
    messages,
    onCancel,
    onSavePress,
    saveButtonType,
    showLoadingState,
    variant = "material",
  } = props;

  return (
    <>
      <Form id={deleteFormId} action={props.deleteActionUrl} method="POST" />
      <div className="flex h-full items-center justify-between p-4">
        <div>
          {!props.hideButtons?.delete ? (
            <Button
              variant="danger"
              isDisabled={disableDelete || state !== "idle"}
              onPress={() => setDeleteModalOpen(true)}
            >
              {messages?.deleteButtonMessage ?? (
                <FormattedMessage id="generic.label.delete" defaultMessage="Delete" />
              )}
            </Button>
          ) : null}
        </div>
        <ButtonGroup>
          {cancelRouteDefinition && (
            <DefinedRouteButtonLink
              routeDefinition={cancelRouteDefinition}
              routeParams={cancelRouteDefinitionParams}
              keepSearchParams={keepSearchParams}
              variant={props.buttonVariants?.cancel ?? "secondary"}
            >
              {messages?.cancelButtonMessage ?? (
                <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
              )}
            </DefinedRouteButtonLink>
          )}
          {onCancel ? (
            <Button
              variant={props.buttonVariants?.cancel ?? "secondary"}
              isDisabled={state !== "idle"}
              onPress={onCancel}
            >
              {messages?.cancelButtonMessage ?? (
                <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
              )}
            </Button>
          ) : null}
          {!props.hideButtons?.save ? (
            <Button
              variant={props.buttonVariants?.save ?? "primary"}
              type={saveButtonType ?? "submit"}
              form={formId}
              name="intent"
              value="save"
              onPress={onSavePress}
              isDisabled={disableSave || state !== "idle"}
              isLoading={showLoadingState ? state === "submitting" : undefined}
            >
              {messages?.saveButtonMessage ?? (
                <FormattedMessage id="generic.label.save" defaultMessage="Save" />
              )}
            </Button>
          ) : null}
        </ButtonGroup>

        <DeleteModal
          onCancel={() => setDeleteModalOpen(false)}
          type="submit"
          name="intent"
          value="delete"
          form={deleteFormId}
          isOpen={deleteModalOpen}
          isLoading={state === "submitting"}
          variant={variant}
        />
      </div>
    </>
  );
}

export const actionSubmitFooterIntentSchema = z.object({
  intent: z.enum(["save", "delete"]).optional(),
});
