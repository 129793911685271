import { useMemo } from "react";

import { Badge } from "@new-black/lyra";

import { useEnumValuesQuery } from "components/suite-composite/hooks/use-enum-values";

export function LoyaltyProgramUsageTypeBadges({ value }: { value: number }) {
  const { getName, valuesArray } = useEnumValuesQuery("LoyaltyPointUsageOptions", false);

  const usageTypes = useMemo(() => {
    if (!valuesArray?.length) return [];

    if (value === 0) {
      return [getName(value)];
    }

    return valuesArray
      ?.filter((entry) => entry.Value !== 0 && (value & entry.Value) === entry.Value)
      .map((entry) => entry.Name);
  }, [getName, value, valuesArray]);

  return (
    <>
      {usageTypes.length
        ? usageTypes.map((entry, index) => (
            <Badge key={index} variant="transparent">
              {entry}
            </Badge>
          ))
        : null}
    </>
  );
}
