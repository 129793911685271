import useEnumValues from "util/hooks/use-enum-values";

export const UserTypesEnumName = "UserTypes";

const useUserTypes = () => {
  const {
    isLoading,
    isLoadingWithoutPreviousResponse: isLoadingWithoutResponse,
    values: userTypes,
  } = useEnumValues(UserTypesEnumName);

  return { userTypes, isLoading, isLoadingWithoutResponse };
};

export default useUserTypes;
