import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import { getAddressFieldVisibilityTypeTranslator } from "types/translations-enums/address-field-visibility-type";

import { useEnumValuesQuery } from "../hooks/use-enum-values";

export interface IAddressFieldVisibilityTypeSelect {
  name?: string;
  errorMessage?: string;
  value?: number;
  onChange: (val?: number) => void;
  isRequired?: boolean;
  hideHintLabel?: boolean;
  disableClearLogic?: boolean;
}

export const AddressFieldVisibilityTypeSelect = ({
  disableClearLogic,
  errorMessage,
  hideHintLabel,
  isRequired,
  name,
  onChange,
  value,
}: IAddressFieldVisibilityTypeSelect) => {
  const intl = useIntl();

  const translateAddressFieldVisibilityType = useMemo(
    () => getAddressFieldVisibilityTypeTranslator(intl),
    [intl],
  );

  const { valuesArray } = useEnumValuesQuery("VisibilityType", false);

  const items = useMemo(
    () =>
      valuesArray?.map((enumValue) => ({
        ...enumValue,
        Name: translateAddressFieldVisibilityType(enumValue.Value) ?? enumValue.Name,
      })) ?? [],
    [translateAddressFieldVisibilityType, valuesArray],
  );

  const selectedValue = useMemo(() => items.find((item) => item.Value === value), [items, value]);

  return (
    <Select
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      name={name ?? ""}
      value={selectedValue}
      onChange={(newValue) => onChange(newValue?.Value)}
      items={items}
      label={intl.formatMessage({ id: "generic.label.visibility", defaultMessage: "Visibility" })}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
      selectRenderElements={(item) => ({ label: item.Name })}
      getItemId={(item) => item.Value}
      getLabel={(item) => item.Name}
      disableClearLogic={disableClearLogic}
    />
  );
};
