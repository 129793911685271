import { CoreManagement } from "@springtree/eva-services-core-management";

import { listStockMutationReasonsQuery } from "models/stock-mutations";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

import { useStockMutationReasonById } from "./use-stock-mutation-reason-by-id";

export const { SingleIDSearchListField: StockMutationReasonsIDSearchListField } =
  SearchListFieldGenerator<
    CoreManagement.ListStockMutationReasons,
    { ID?: number; Name?: string; Description?: string },
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page.map((item) => ({
        ID: item.ID,
        Name: item.Name,
        Description: item.Description,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.reason",
      defaultMessage: "Reason",
    }),
    getSecondaryLabel: (item) => item.Description,
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listStockMutationReasonsQuery,
        initialRequest: { PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
    useItemByID: useStockMutationReasonById,
  });

export const generateLyraListStockMutationReasonsSearchListField = (
  req: EVA.Core.Management.ListStockMutationReasons,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListStockMutationReasons,
    { ID?: number; Name?: string; Description?: string },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name!,
    selectRenderElements: (item) => ({
      label: item.Name!,
    }),
    getItemFromResponse: (resp) =>
      resp?.Result?.Page.map((item) => ({
        ID: item.ID,
        Name: item.Name,
        Description: item.Description,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.reason",
      defaultMessage: "Reason",
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listStockMutationReasonsQuery,
        initialRequest: {
          ...req,
          PageConfig: { ...req.PageConfig, Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
    useItemByID: useStockMutationReasonById,
  });
