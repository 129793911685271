import { Dispatch, SetStateAction, memo } from "react";

import { Separator } from "@new-black/lyra";

import { useWorkspaces } from "features/workspaces";
import { IUseWorkspacesProps } from "features/workspaces/workspaces.types";

import { MenuState } from "./menu.types";
import { MenuOUSelector } from "./menu-ou-selector";
import { MenuWorkspaceSelector } from "./menu-workspace-selector";

type SecondaryMenuProps = {
  menuState: MenuState;
  setMouseEntered: Dispatch<SetStateAction<boolean>>;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  showOUSelector: boolean;
};

export const SecondaryMenu = memo(
  ({ menuState, setIsFocused, setMouseEntered, showOUSelector }: SecondaryMenuProps) => {
    if (showOUSelector) {
      return (
        <div className="mb-16 mt-auto px-4">
          <Separator />
          <div className="my-4 flex flex-col gap-y-2">
            <MenuOUSelector
              menuState={menuState}
              setMouseEntered={setMouseEntered}
              setIsFocused={setIsFocused}
            />
          </div>
        </div>
      );
    }
    return null;
  },
);

type SubMenuFooterWithWorkspacesProps<TWorkspace> = SecondaryMenuProps & {
  workspace: IUseWorkspacesProps<TWorkspace>;
};

export const SecondaryMenuWithWorkspaces = memo(
  <TWorkspace,>({
    menuState,
    setIsFocused,
    setMouseEntered,
    showOUSelector,
    workspace,
  }: SubMenuFooterWithWorkspacesProps<TWorkspace>) => {
    const workspaceState = useWorkspaces(workspace);

    if (showOUSelector || workspaceState.selectedWorkspace) {
      return (
        <div className="mb-16 mt-auto px-4">
          <Separator />
          <div className="my-4 flex flex-col gap-y-2">
            {showOUSelector ? (
              <MenuOUSelector
                menuState={menuState}
                setMouseEntered={setMouseEntered}
                setIsFocused={setIsFocused}
              />
            ) : null}
            <MenuWorkspaceSelector
              menuState={menuState}
              setMouseEntered={setMouseEntered}
              setIsFocused={setIsFocused}
              workspaceState={workspaceState}
            />
          </div>
        </div>
      );
    }
    return null;
  },
);
