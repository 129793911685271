import { Dispatch, SetStateAction, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRevalidator } from "react-router-dom";

import { Dialog } from "@material-ui/core";
import { SvgIcon } from "@new-black/lyra";
import { defer } from "lodash";

import { OrganizationUnitSelectorMenu } from "components/suite-composite/organization-unit-selector";
import { IOrganizationUnitMenuItem } from "components/suite-composite/organization-unit-selector/organization-unit-selector.types";
import Text from "components/suite-ui/text";
import { queryClient } from "util/query-client";

import { useOUSelector } from "./ou-selector/use-ou-selector";
import { MenuOUSelectorProps } from "./menu.types";
import { MenuButton } from "./menu-button";

export const MenuOUSelector = ({
  menuState,
  setMouseEntered,
  setIsFocused,
}: MenuOUSelectorProps) => {
  const intl = useIntl();
  const { selectedOrganizationUnit } = useOUSelector();

  const [ouSelectorOpen, setOUSelectorOpen] = useState(false);

  if (selectedOrganizationUnit) {
    return (
      <>
        <MenuButton
          icon={<SvgIcon name="organization-unit" />}
          labelKey={intl.formatMessage({
            id: "generic.label.organization-unit",
            defaultMessage: "Organization unit",
          })}
          labelValue={selectedOrganizationUnit.Name ?? "-"}
          menuState={menuState}
          setMouseEntered={setMouseEntered}
          setIsFocused={setIsFocused}
          onClick={() => setOUSelectorOpen(true)}
        />
        {ouSelectorOpen && <OUSelectorDialog open={ouSelectorOpen} setOpen={setOUSelectorOpen} />}
      </>
    );
  }

  return null;
};

const OUSelectorDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const anchorEl = useRef(null);
  const {
    debouncedInputValue,
    direction,
    handleItemClick,
    menuItems,
    onGoBack,
    onGoNext,
    parentOrganizationUnit,
    selectedOrganizationUnit,
  } = useOUSelector();

  const revalidator = useRevalidator();
  const handleChange = async (item: IOrganizationUnitMenuItem) => {
    handleItemClick(item);
    setOpen(false);
    queryClient.invalidateQueries();
    queryClient.resetQueries();
    queryClient.removeQueries();
    defer(() => revalidator.revalidate());
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
      <div className="p-5">
        <Text variant="h3">
          <FormattedMessage
            id="generic.label.select-an-organization-unit"
            defaultMessage="Select an organization unit"
          />
        </Text>
      </div>
      <div ref={anchorEl} />
      <OrganizationUnitSelectorMenu
        direction={direction}
        debouncedInputValue={debouncedInputValue}
        menuItems={menuItems}
        onGoBack={onGoBack}
        onGoNext={onGoNext}
        handleItemClick={handleChange}
        selectedOrganizationUnit={selectedOrganizationUnit}
        parentOrganizationUnit={parentOrganizationUnit}
      />
    </Dialog>
  );
};
