import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const printTaskTypesServiceState = builders.buildServiceState({
  service: Core.GetEnumValues,
  key: "Core:GetEnumValues:PrintTaskType",
  defaultRequest: {
    Name: "PrintTaskType",
  },
  requireLoggedIn: true,
  allowEmptyRequest: false,
});

export default printTaskTypesServiceState;
