import { useIntl } from "react-intl";

import { TextFieldArea } from "@new-black/lyra";
import { Field, FieldProps } from "formik";

export default function ProductPropertyValuesTextarea() {
  const intl = useIntl();

  return (
    <Field name="propertyValues">
      {({ field, form, meta }: FieldProps<string | undefined>) => (
        <TextFieldArea
          {...field}
          rows={13}
          fullWidth
          isRequired
          maxRows={13}
          value={field.value || ""}
          style={{ resize: "vertical" }}
          isInvalid={!!meta.error && meta.touched}
          onChange={(newValue) => form.setFieldValue(field.name, newValue)}
          errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
          label={intl.formatMessage({ id: "generic.label.values", defaultMessage: "Values" })}
        />
      )}
    </Field>
  );
}
