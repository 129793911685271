export default {
  module: {
    path: "/pim",
  },
  productBundles: {
    overview: {
      path: "/pim/product-bundles",
    },
    details: {
      path: "/pim/product-bundles/:id",
    },
  },
  productRelations: {
    overview: {
      path: "/pim/product-relations",
    },
    create: {
      path: "/pim/product-relations/new",
    },
    details: {
      path: "/pim/product-relations/:id",
    },
  },
  productSets: {
    overview: {
      path: "/pim/product-sets",
    },
    create: {
      path: "/pim/product-sets/new",
    },
    details: {
      path: "/pim/product-sets/:id",
      editGeneralInfo: {
        path: "/pim/product-sets/:id/edit-general-info",
      },
      editEcomFilters: {
        path: "/pim/product-sets/:id/edit-ecom-filters",
      },
    },
  },

  products: {
    path: "/pim/products",

    productsOverview: {
      overview: {
        path: "/pim/products/products-overview",

        downloadProductContent: {
          path: "/pim/products/products-overview/download-product-content",
        },
        uploadProductInformation: {
          path: "/pim/products/products-overview/upload-product-information",
        },
        uploadProductContent: {
          path: "/pim/products/products-overview/upload-product-content",
        },
      },

      details: {
        path: "/pim/products/products-overview/:id",

        contentPreview: {
          path: "/pim/products/products-overview/:id/content-preview",
        },
      },
    },

    productProperties: {
      overview: {
        path: "/pim/products/product-properties",

        create: {
          path: "/pim/products/product-properties/new",
        },
      },
    },
    assortments: {
      overview: {
        path: "/pim/products/assortments",
      },
      details: {
        path: "/pim/products/assortments/:id",
        edit: {
          path: "/pim/products/assortments/:id/edit",
        },
        attachOus: {
          path: "/pim/products/assortments/:id/attach-ous",
        },
        editProductSettings: {
          path: "/pim/products/assortments/:id/products/:productId/settings",
        },
        deleteProduct: {
          path: "/pim/products/assortments/:id/products/:productId/delete",
        },
        downloadProducts: {
          path: "/pim/products/assortments/:id/products/download",
        },
        uploadProducts: {
          path: "/pim/products/assortments/:id/products/upload",
        },
        addProduct: {
          path: "/pim/products/assortments/:id/products/add",
        },
      },
      create: {
        path: "/pim/products/assortments/new",
      },
    },
  },

  categories: {
    path: "/pim/categories",
    oldAdminPath: "/product-management/categories",
  },

  plugin: {
    path: "/pim/plugin",
  },

  productRequirements: {
    overview: {
      path: "/pim/product-requirements",
      new: {
        path: "/pim/product-requirements/new",
      },
    },
    details: {
      path: "/pim/product-requirements/:productId",
      create: {
        path: "/pim/product-requirements/:productId/new",
      },
      edit: {
        path: "/pim/product-requirements/:productId/:requirementId/edit",
      },
      delete: {
        path: "/pim/product-requirements/:productId/:requirementId/delete",
      },
    },
  },

  searchConfiguration: {
    path: "/pim/search-configuration",
    oldAdminPath: "/product-search/configuration",
  },

  supplierProducts: {
    path: "/pim/supplier-products",
    oldAdminPath: "/pim/product-supplier",
  },

  unitsOfMeasure: {
    overview: {
      path: "/pim/units-of-measure",
    },
    create: {
      path: "/pim/units-of-measure/new",
    },
    details: {
      path: "/pim/units-of-measure/:id",
      edit: {
        path: "/pim/units-of-measure/:id/edit",
      },
      addProduct: {
        path: "/pim/units-of-measure/:id/add-product",
      },
      deleteUnitOfMeasure: {
        path: "/pim/units-of-measure/:id/delete",
      },
      deleteProDuctFromUnitOfMeasure: {
        path: "/pim/units-of-measure/:id/delete-product/:productUomId",
      },
    },
  },
} as const;
