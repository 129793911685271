import { clone } from "lodash";

export const parseEVAEnumValues = (
  value: number | undefined,
  enumValues?: EVA.Core.GetEnumValuesResponse.EnumValue[],
) => {
  if (value === undefined || enumValues === undefined) {
    return undefined;
  } else {
    let enumInputValue = value!;
    type EnumValue = (typeof enumValues)[0];
    const mapToEnumValues = () => {
      const output: Array<EnumValue> | undefined = [];
      const clonedEnums: Array<EnumValue> | undefined = clone(enumValues);
      const sortedEnumValues: Array<EnumValue> | undefined = clonedEnums?.sort(
        (a, b) => b.Value - a.Value,
      );

      if (sortedEnumValues) {
        while (enumInputValue > 0) {
          sortedEnumValues
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .forEach((v) => {
              if (v.Value <= enumInputValue) {
                enumInputValue -= v.Value;
                output.unshift(v);
              }
            });
        }
      }
      return output;
    };

    const mappedValues = mapToEnumValues();
    return mappedValues;
  }
};
