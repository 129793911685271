import { IntlShape } from "react-intl";

import { Core } from "@springtree/eva-services-core";

import { getEnumValuesQuery } from "models/enum-values";
import { getOrderPropertyLabel, OrderProperties } from "types/translations-enums";
import { ONE_HOUR } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

export interface IOrderPropertiesSearchListFieldItem {
  Value: number;
  Name: string;
}

export const generateOrderPropertiesSearchListField = (
  frontendFilter?: (value: IOrderPropertiesSearchListFieldItem) => boolean,
  intlObject?: IntlShape,
) =>
  SearchListFieldGenerator<Core.GetEnumValues, IOrderPropertiesSearchListFieldItem, "Value">({
    getItemFromResponse: (response) => {
      const items = response?.Values?.map((entry) => ({
        Value: entry.Value,
        Name:
          getOrderPropertyLabel(
            entry.Name.toLowerCase() as OrderProperties,
            intlObject ?? intlAccessor,
          ) ?? entry.Name,
      }));

      if (frontendFilter) {
        return items?.filter(frontendFilter);
      }

      return items;
    },
    idKey: "Value",
    labelKey: "Name",
    defaultLabel: (intlObject ?? intlAccessor).formatMessage({
      id: "generic.label.order-properties",
      defaultMessage: "Order properties",
    }),
    useItemsByID: (ids, currentListItems) => ({
      data: currentListItems?.filter((entry) => ids?.includes(entry.Value)),
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getEnumValuesQuery,
        initialRequest: { Name: "OrderProperties" },
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });

export const generateOrderPropertiesLyraSearchListField = (
  frontendFilter?: (value: IOrderPropertiesSearchListFieldItem) => boolean,
  intlObject?: IntlShape,
) =>
  LyraSearchListFieldGenerator<Core.GetEnumValues, IOrderPropertiesSearchListFieldItem, number>({
    getItemFromResponse: (response) => {
      const items = response?.Values?.map((entry) => ({
        Value: entry.Value,
        Name:
          getOrderPropertyLabel(
            entry.Name.toLowerCase() as OrderProperties,
            intlObject ?? intlAccessor,
          ) ?? entry.Name,
      }));

      if (frontendFilter) {
        return items?.filter(frontendFilter);
      }

      return items;
    },
    getItemId: (item) => item.Value,
    getLabel: (item) => item.Name,
    defaultLabel: (intlObject ?? intlAccessor).formatMessage({
      id: "generic.label.order-properties",
      defaultMessage: "Order properties",
    }),
    useItemsByID: (ids, currentListItems) => ({
      data: currentListItems?.filter((entry) => ids?.includes(entry.Value)),
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        query: getEnumValuesQuery,
        initialRequest: { Name: "OrderProperties" },
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });
