export enum FinancialEventTypes {
  Sales = 10,
  SalesTax = 11,
  SalesDiscounts = 12,
  Purchase = 20,
  PurchaseTax = 21,
  PurchasePriceVariance = 22,
  PurchaseInvoiceDispute = 23,
  PurchaseInvoiceDisputeResolved = 24,
  PurchaseDiscounts = 25,
  CostOfGoods = 30,
  Payment = 40,
  PaymentEndRounding = 41,
  PaymentSettlement = 42,
  PaymentCapture = 43,
  PaymentRelease = 44,
  CashAdjustment = 50,
  StockMutation = 60,
  StockMutationAutomaticCorrection = 61,
  StockSold = 62,
  StockReceived = 63,
  Shipment = 70,
}
