import { Auditing } from "@springtree/eva-services-auditing";
import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createEVAService } from "util/http";
import { createServiceQuery } from "util/query";

// GetCurrentUser
export const getCurrentUserService = createEVAService(Core.GetCurrentUser);

export const {
  serviceLoaderQuery: getCurrentUserLoaderQuery,
  serviceQuery: getCurrentUserQuery,
  serviceQueryKeys: getCurrentUserQueryKeys,
  useServiceQueryHook: useGetCurrentUserQuery,
} = createServiceQuery(Core.GetCurrentUser, undefined, true);

export const { serviceQuery: getLoginOrganizationUnitsForUserQuery } = createServiceQuery(
  Core.GetLoginOrganizationUnitsForUser,
);

// SearchUsers
export const searchUsersService = createEVAService(Core.SearchUsers);
export const {
  serviceLoaderQuery: searchUsersLoaderQuery,
  serviceQuery: searchUsersQuery,
  serviceQueryKeys: searchUsersQueryKeys,
  useServiceQueryHook: useSearchUsersQuery,
} = createServiceQuery(Core.SearchUsers);

export const {
  serviceLoaderQuery: getUserRequirementsLoaderQuery,
  serviceQuery: getUserRequirementsQuery,
  serviceQueryKeys: getUserRequirementsQueryKeys,
  useServiceQueryHook: useGetUserRequirementsQuery,
  useServiceQueryWithRequest: useGetUserRequirementsQueryWithRequest,
} = createServiceQuery(CoreManagement.GetUserRequirements);

export const {
  serviceLoaderQuery: getAvailableUserRequirementsPropertiesLoaderQuery,
  serviceQuery: getAvailableUserRequirementsPropertiesQuery,
  serviceQueryKeys: getAvailableUserRequirementsPropertiesQueryKeys,
  useServiceQueryHook: useGetAvailableUserRequirementsPropertiesQuery,
  useServiceQueryWithRequest: useGetAvailableUserRequirementsPropertiesQueryWithRequest,
} = createServiceQuery(CoreManagement.GetAvailableUserRequirementsProperties);

export const {
  serviceLoaderQuery: getUserLoaderQuery,
  serviceQuery: getUserQuery,
  serviceQueryKeys: getUserQueryKeys,
  useServiceQueryHook: useGetUserQuery,
} = createServiceQuery(Core.GetUser, true);

export const {
  serviceLoaderQuery: listEmployeeDatasLoaderQuery,
  serviceQuery: listEmployeeDatasQuery,
  serviceQueryKeys: listEmployeeDatasQueryKeys,
  useServiceQueryHook: useListEmployeeDatasQuery,
} = createServiceQuery(CoreManagement.ListEmployeeDatas, true);

export const {
  serviceLoaderQuery: listAPIKeysLoaderQuery,
  serviceQuery: listAPIKeysQuery,
  serviceQueryKeys: listAPIKeysQueryKeys,
  useServiceQueryWithRequest: useListAPIKeysQueryWithRequest,
} = createServiceQuery(Core.ListApiKeys, true);

export const {
  serviceLoaderQuery: getAvailableTwoFactorAuthenticatorsLoaderQuery,
  serviceQuery: getAvailableTwoFactorAuthenticatorsQuery,
  serviceQueryKeys: getAvailableTwoFactorAuthenticatorsQueryKeys,
  useServiceQueryHook: useGetAvailableTwoFactorAuthenticatorsQuery,
} = createServiceQuery(Core.GetAvailableTwoFactorAuthenticators);

export const { serviceQuery: checkEmailAddressAvailabilityQuery } = createServiceQuery(
  Core.CheckEmailAddressAvailability,
  true,
);

export const {
  serviceLoaderQuery: generateTemporaryPasswordForUserLoaderQuery,
  useServiceQueryHook: useGenerateTemporaryPasswordForUserQuery,
} = createServiceQuery(Core.GenerateTemporaryPasswordForUser, true, true);

export const {
  serviceQuery: getUserAssociationTypesQuery,
  useServiceQueryHook: useGetUserAssociationTypesQuery,
} = createServiceQuery(Core.GetUserAssociationTypes);

export const { serviceQuery: validateFiscalIDQuery } = createServiceQuery(
  Auditing.ValidateFiscalID,
  true,
);
