import { isNil } from "lodash";

import { AddressFieldVisibilityType } from "types/address-field-visibility-type";

export const isAddressFieldVisible = (
  addressRequirements: EVA.Core.GetAddressRequirementsResponse | undefined,
  field: keyof EVA.Core.AddressDto,
) => {
  const fieldRequirements = addressRequirements?.AddressRequirements?.[field];

  return (
    !isNil(fieldRequirements) &&
    fieldRequirements?.Visible !== (AddressFieldVisibilityType.NotVisible as number)
  );
};

export const isAddressFieldRequired = (
  addressRequirements: EVA.Core.GetAddressRequirementsResponse | undefined,
  field: keyof EVA.Core.AddressDto,
) => {
  const fieldRequirements = addressRequirements?.AddressRequirements?.[field];

  return fieldRequirements?.Visible === (AddressFieldVisibilityType.VisibleAndRequired as number);
};
