import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox, FieldGroup, NumberField, TextField } from "@new-black/lyra";

import { generatePaymentTypeSearchListField } from "components/suite-composite/generate-payment-type-autocomplete";

import { LoyaltyPaymentTaxHandlingSelect } from "../loyalty-payment-tax-handling-select";
import { UserBudgetDeductionSelect } from "../user-budget-deduction-select";

import {
  LoyaltyProgramPaymentTypeForm,
  LoyaltyProgramPaymentTypeFormErrors,
  loyaltyProgramPaymentTypeFormKeys,
} from "./types";

const { SingleIDSearchListField: PaymentTypeIDSearchListField } =
  generatePaymentTypeSearchListField({
    PaymentMethodName: "LoyaltyProgram",
  });

export const LoyaltyProgramPaymentTypeFormFields = ({
  errors,
  initialValues,
}: {
  initialValues?: LoyaltyProgramPaymentTypeForm;
  errors?: LoyaltyProgramPaymentTypeFormErrors;
}) => {
  const intl = useIntl();

  const [paymentTypeID, setPaymentTypeID] = useState<number>();

  return (
    <FieldGroup>
      {initialValues?.PaymentTypeID ? null : (
        <>
          <PaymentTypeIDSearchListField.Controlled
            value={paymentTypeID}
            onChange={setPaymentTypeID}
            isRequired
            errorMessage={errors?.PaymentTypeID}
            isInvalid={!!errors?.PaymentTypeID}
            label={intl.formatMessage({
              id: "generic.label.payment-type",
              defaultMessage: "Payment type",
            })}
          />
          {paymentTypeID !== undefined ? (
            <input
              hidden
              readOnly
              name={loyaltyProgramPaymentTypeFormKeys.PaymentTypeID}
              value={paymentTypeID}
            />
          ) : null}
        </>
      )}

      <TextField
        name={loyaltyProgramPaymentTypeFormKeys.BackendID}
        defaultValue={initialValues?.BackendID}
        errorMessage={errors?.BackendID}
        isInvalid={!!errors?.BackendID}
        label={intl.formatMessage({ id: "generic.label.backend-id", defaultMessage: "Backend ID" })}
      />

      <NumberField
        isRequired
        name={loyaltyProgramPaymentTypeFormKeys.Sequence}
        defaultValue={initialValues?.Sequence}
        errorMessage={errors?.Sequence}
        isInvalid={!!errors?.Sequence}
        label={intl.formatMessage({ id: "generic.label.sequence", defaultMessage: "Sequence" })}
        minValue={1}
        formatOptions={{ maximumFractionDigits: 0 }}
      />

      <LoyaltyPaymentTaxHandlingSelect
        name={loyaltyProgramPaymentTypeFormKeys.TaxHandling}
        initialValue={initialValues?.TaxHandling}
        errorMessage={errors?.TaxHandling}
      />

      <UserBudgetDeductionSelect
        name={loyaltyProgramPaymentTypeFormKeys.UserBudgetDeduction}
        initialValue={initialValues?.UserBudgetDeduction}
        errorMessage={errors?.UserBudgetDeduction}
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.Budget}
        defaultValue={initialValues?.Budget}
        errorMessage={errors?.Budget}
        isInvalid={!!errors?.Budget}
        label={intl.formatMessage({
          id: "generic.label.budget",
          defaultMessage: "Budget",
        })}
        minValue={0}
        hideStepper
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.UserBudget}
        defaultValue={initialValues?.UserBudget}
        errorMessage={errors?.UserBudget}
        isInvalid={!!errors?.UserBudget}
        label={intl.formatMessage({
          id: "generic.label.user-budget",
          defaultMessage: "User budget",
        })}
        minValue={0}
        hideStepper
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.UserMaximumUsage}
        defaultValue={initialValues?.UserMaximumUsage}
        errorMessage={errors?.UserMaximumUsage}
        isInvalid={!!errors?.UserMaximumUsage}
        label={intl.formatMessage({
          id: "generic.label.user-maximum-usage",
          defaultMessage: "User maximum usage",
        })}
        minValue={0}
        hideStepper
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.MaximumAmountPerOrder}
        defaultValue={initialValues?.MaximumAmountPerOrder}
        errorMessage={errors?.MaximumAmountPerOrder}
        isInvalid={!!errors?.MaximumAmountPerOrder}
        label={intl.formatMessage({
          id: "generic.label.maximum-amount-per-order",
          defaultMessage: "Maximum amount per order",
        })}
        minValue={0}
        hideStepper
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.MaximumPercentageOfUserBudgetPerOrder}
        defaultValue={initialValues?.MaximumPercentageOfUserBudgetPerOrder}
        errorMessage={errors?.MaximumPercentageOfUserBudgetPerOrder}
        isInvalid={!!errors?.MaximumPercentageOfUserBudgetPerOrder}
        label={intl.formatMessage({
          id: "generic.label.maximum-percentage-of-user-budget-per-order",
          defaultMessage: "Maximum percentage of user budget per order",
        })}
        minValue={0}
        maxValue={100}
        hideStepper
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.OverallQuantityLimitPerOrder}
        defaultValue={initialValues?.OverallQuantityLimitPerOrder}
        errorMessage={errors?.OverallQuantityLimitPerOrder}
        isInvalid={!!errors?.OverallQuantityLimitPerOrder}
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-order",
          defaultMessage: "Quantity limit per order",
        })}
        minValue={1}
        formatOptions={{ maximumFractionDigits: 0 }}
      />

      <NumberField
        name={loyaltyProgramPaymentTypeFormKeys.OverallQuantityLimitPerUser}
        defaultValue={initialValues?.OverallQuantityLimitPerUser}
        errorMessage={errors?.OverallQuantityLimitPerUser}
        isInvalid={!!errors?.OverallQuantityLimitPerUser}
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-user",
          defaultMessage: "Quantity limit per user",
        })}
        minValue={1}
        formatOptions={{ maximumFractionDigits: 0 }}
      />

      <Checkbox
        name={loyaltyProgramPaymentTypeFormKeys.ExcludeFromProgramBudget}
        defaultSelected={initialValues?.ExcludeFromProgramBudget}
      >
        <FormattedMessage
          id="generic.label.excluded-from-program-budget"
          defaultMessage="Exclude from program budget"
        />
      </Checkbox>

      <Checkbox
        name={loyaltyProgramPaymentTypeFormKeys.Required}
        defaultSelected={initialValues?.Required}
      >
        <FormattedMessage id="generic.label.required" defaultMessage="Required" />
      </Checkbox>

      <Checkbox
        name={loyaltyProgramPaymentTypeFormKeys.AllowPartialPayment}
        defaultSelected={initialValues?.AllowPartialPayment}
      >
        <FormattedMessage
          id="generic.label.allow-partial-payment"
          defaultMessage="Allow partial payment"
        />
      </Checkbox>
    </FieldGroup>
  );
};
