import { CoreManagement } from "@springtree/eva-services-core-management";

import { listPaymentMethodsQuery, useGetPaymentMethodByIDQuery } from "models/payment-methods";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export interface IPaymentMethodAutocompleteItem {
  ID: number;
  Name: string;
}

function usePaymentMethodById(
  id: number | undefined,
  itemsFromList: IPaymentMethodAutocompleteItem[] | undefined,
) {
  const paymentMethodFromList = itemsFromList?.find((el) => el.ID === id);

  const { data, isFetching } = useGetPaymentMethodByIDQuery(
    id &&
      (typeof id === "number" || (typeof id === "string" && !isNaN(parseFloat(id)))) &&
      !paymentMethodFromList
      ? { ID: typeof id === "string" ? parseFloat(id) : id }
      : undefined,
    {
      loaderKey: id ? [id] : undefined,
      refetchOnMount: false,
    },
  );

  return { data: data ?? paymentMethodFromList, isLoading: isFetching };
}

export const generatePaymentMethodAutocomplete = (
  initialFilters?: Partial<EVA.Core.ListPaymentMethodsFilter>,
) => {
  const Autocompletes = AutocompleteGenerator<
    CoreManagement.ListPaymentMethods,
    IPaymentMethodAutocompleteItem,
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((paymentMethod) => ({
        Name: paymentMethod.Name,
        ID: paymentMethod.ID,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.payment-method",
      defaultMessage: "Payment method",
    }),
    useItemByID: usePaymentMethodById,
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: listPaymentMethodsQuery,
        initialRequest: { PageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return Autocompletes;
};

export const generateLyraPaymentMethodSearchListField = (
  initialFilters?: Partial<EVA.Core.ListPaymentMethodsFilter>,
) => {
  const LyraPaymentMethodSearchListField = LyraSearchListFieldGenerator<
    CoreManagement.ListPaymentMethods,
    IPaymentMethodAutocompleteItem,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((paymentMethod) => ({
        Name: paymentMethod.Name,
        ID: paymentMethod.ID,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.payment-method",
      defaultMessage: "Payment method",
    }),
    useItemByID: usePaymentMethodById,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listPaymentMethodsQuery,
        initialRequest: {
          PageConfig: {
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...initialFilters, Name: initialFilters?.Name },
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return LyraPaymentMethodSearchListField;
};
