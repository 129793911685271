import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Spinner, SvgIcon, TextField, TextFieldProps, Tooltip } from "@new-black/lyra";

import { IFiscalIDValidatedInputProps } from "./fiscal-id-validated-input.types";
import useCheckFiscalIDAvailability from "./use-validate-fiscal-id";

/**
 * String input that checks the validity of a fiscal id. It will trigger a request to
 * `ValidateFiscalID` service if the provided value is a valid fiscal id.
 * Use `validationException` prop if you want to omit the validation for a particular value (or values).
 * Note that the error / warning states and helper text for the validity have a higher priority than `error`,
 * `warning` and `helperText` that are passed as props.
 */
const LyraFiscalIDValidatedBaseInput = ({
  onAfterValidation,
  validationException,
  validationMessageVariant = "error",
  value,
  ...props
}: TextFieldProps & IFiscalIDValidatedInputProps) => {
  const intl = useIntl();

  const [localValue, setLocalValue] = useState<string>();

  // Make sure that the local value and value prop are in sync
  useEffect(() => {
    setLocalValue(value as string);
  }, [value]);

  const { isLoading, isValid, setIsValid, shouldValidate } = useCheckFiscalIDAvailability(
    localValue,
    onAfterValidation,
    validationException,
  );

  const endIcon = useMemo(() => {
    if (isLoading) {
      return <Spinner size="small" />;
    } else if (isValid === true && shouldValidate) {
      return <SvgIcon name="checkmark" className="h-5 w-5 text-success" />;
    } else if (isValid === false && shouldValidate && validationMessageVariant === "error") {
      return (
        <Tooltip
          tooltip={intl.formatMessage({
            id: "users.fiscal-id.invalid-warning",
            defaultMessage: "This Fiscal ID is not valid.",
          })}
        >
          <SvgIcon name="alert-error" className="h-5 w-5 text-error" />
        </Tooltip>
      );
    }

    return null;
  }, [intl, isValid, isLoading, shouldValidate, validationMessageVariant]);

  return (
    <TextField
      {...props}
      value={value}
      label={
        props.label ??
        intl.formatMessage({ id: "generic.label.fiscal-id", defaultMessage: "Fiscal ID" })
      }
      onChange={(newValue) => {
        props.onChange?.(newValue);
        setLocalValue(newValue);
        setIsValid(undefined);
        onAfterValidation?.(false);
      }}
      isInvalid={
        (validationMessageVariant === "error" && isValid === false && shouldValidate) ||
        (props.isInvalid && isValid === false)
      }
      errorMessage={
        isValid === false && shouldValidate && validationMessageVariant === "error"
          ? intl.formatMessage({
              id: "users.fiscal-id.invalid-warning",
              defaultMessage: "This Fiscal ID is not valid.",
            })
          : props.errorMessage
      }
      description={
        validationMessageVariant === "warning" &&
        isValid === false &&
        shouldValidate &&
        !props.errorMessage
          ? intl.formatMessage({
              id: "users.fiscal-id.invalid-warning",
              defaultMessage: "This Fiscal ID is not valid.",
            })
          : undefined
      }
      endSlot={endIcon || undefined}
    />
  );
};

export default LyraFiscalIDValidatedBaseInput;
