import { useCallback, useState } from "react";

import { Blobs } from "@springtree/eva-services-blobs";

import useCallService from "./suite-react-hooks/use-call-service";
import { useHandleErrorResponse } from "./suite-react-hooks/use-handle-error-response";

const useStoreBlobMutation = () => {
  const handleErrorResponse = useHandleErrorResponse();

  const [isLoading, setIsLoading] = useState(false);

  const { callService: storeBlobMutation } = useCallService({
    service: Blobs.StoreBlob,
    options: {
      onSuccess: (data) => {
        setIsLoading(false);

        return data;
      },
      onError: (error) => {
        setIsLoading(false);

        if (error) {
          handleErrorResponse(error, "Core:UpdateProductBundle");
        }
      },
    },
    disableNotifications: true,
  });

  const storeBlob = useCallback(
    (request: EVA.Blobs.StoreBlob) => {
      setIsLoading(true);

      return storeBlobMutation(request);
    },
    [storeBlobMutation],
  );

  return { isLoading, storeBlob };
};

export default useStoreBlobMutation;
