import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { useEnumValuesQuery } from "../hooks/use-enum-values";

interface ILoyaltyProgramGroupDeactivationReasonsSearchListField {
  value?: number[];
  onChange: (val: number[]) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const LoyaltyProgramGroupDeactivationReasonsSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: ILoyaltyProgramGroupDeactivationReasonsSearchListField) => {
  const intl = useIntl();

  const { isLoading, valuesArray: items } = useEnumValuesQuery(
    "LoyaltyProgramGroupDeactivationReasons",
    false,
  );

  const selectedValue = useMemo<EVA.Core.GetEnumValuesResponse.EnumValue[]>(
    () => items?.filter((item) => value?.includes(item.Value)) ?? [],
    [items, value],
  );

  return (
    <MultiSearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.Value}
      getLabel={(item) => item.Name}
      selectRenderElements={(item) => ({ label: item.Name })}
      value={selectedValue}
      items={items ?? []}
      isLoading={isLoading}
      onChange={(newValue) => onChange(newValue?.map((item) => item.Value) ?? [])}
      label={intl.formatMessage({
        id: "generic.label.deactivation-reasons",
        defaultMessage: "Deactivation reasons",
      })}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
    />
  );
};
