import { useMemo } from "react";

import { builders, hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

const enumValuesServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  service: Core.GetEnumValues,
  key: "EnumValues:Core.GetEnumValues",
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "string" || req?.Name === parameter) {
      return req;
    }
    return { ...req, Name: parameter };
  },
});

const enumValuesValueSelector = selectorFamily({
  key: "EnumValues:Core.GetEnumValues:Values",
  get:
    (parameter) =>
    ({ get }) =>
      get(enumValuesServiceState.response(parameter))?.Values,
});

const useEnumValues = (name?: string) => {
  const values = hooks.useGetState(enumValuesValueSelector(name));

  const isLoading = hooks.useIsLoading({ state: enumValuesServiceState.response(name) });
  const isLoadingWithoutPreviousResponse = useMemo(() => isLoading && !values, [isLoading, values]);

  return {
    values,
    isLoading,
    isLoadingWithoutPreviousResponse,
  };
};

useEnumValues.service = {
  serviceState: enumValuesServiceState,
  value: enumValuesValueSelector,
};

export default useEnumValues;
