import { useGetEnumValuesQuery } from "models/enum-values";

const DISCOUNT_COMPLIANCY_RULES_ENUM_NAME = "DiscountCompliancyRule";

export const useDiscountCompliancyRules = () => {
  const { data, isFetching: isLoading } = useGetEnumValuesQuery(
    { Name: DISCOUNT_COMPLIANCY_RULES_ENUM_NAME },
    { refetchOnMount: false },
  );

  return { data, isLoading };
};

export const enum DiscountCompliancyRule {
  None = 0,
  EmployeeDiscountTaxExclusion = 1,
}
