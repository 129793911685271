import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { getProductPropertyTypeSearchTypeTanslation } from "types/translations-enums/product-property-type-search-types";

import { useEnumValuesQuery } from "../hooks/use-enum-values";

interface IProductPropertySearchTypeSearchListField {
  value?: number[];
  onChange: (val: number[]) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const ProductPropertySearchTypeSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: IProductPropertySearchTypeSearchListField) => {
  const intl = useIntl();

  const { isLoading, valuesArray } = useEnumValuesQuery("ProductPropertyTypeSearchTypes", false);

  const translateProductPropertySearchType = useMemo(
    () => getProductPropertyTypeSearchTypeTanslation(intl),
    [intl],
  );

  const items = useMemo(
    () =>
      valuesArray?.map((entry) => ({
        ...entry,
        Name: translateProductPropertySearchType(entry.Value) ?? entry.Name,
      })),
    [translateProductPropertySearchType, valuesArray],
  );

  const selectedValue = useMemo<EVA.Core.GetEnumValuesResponse.EnumValue[]>(
    () => items?.filter((item) => value?.includes(item.Value)) ?? [],
    [items, value],
  );

  return (
    <MultiSearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.Value}
      getLabel={(item) => item.Name}
      selectRenderElements={(item) => ({ label: item.Name })}
      value={selectedValue}
      items={items ?? []}
      isLoading={isLoading}
      onChange={(newValue) => onChange(newValue?.map((item) => item.Value) ?? [])}
      label={intl.formatMessage({ id: "generic.label.search-type", defaultMessage: "Search type" })}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
    />
  );
};
