import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { SvgIcon, TextField, TextFieldProps } from "@new-black/lyra";

import Spinner from "components/suite-ui/spinner";
import Tooltip from "components/suite-ui/tooltip";

import { IEmailAddressValidatedInputProps } from "./email-address-validated-input.types";
import useCheckEmailAddressAvailability from "./use-check-email-address-availability";

/**
 * String input that checks the availability of the email address in EVA. It will trigger a request to
 * `CheckEmailAddressAvailability` service if the provided value is a valid email address.
 * Use `validationException` prop if you want to omit the validation for a particular value (or values).
 * Note that the error / warning states and helper text for the email availability have a higher priority than `error`,
 * `warning` and `helperText` that are passed as props.
 */
const LyraEmailAddressValidatedBaseInput = ({
  asEmployee,
  onAfterValidation,
  validationException,
  validationMessageVariant = "error",
  value,
  ...props
}: TextFieldProps & IEmailAddressValidatedInputProps) => {
  const intl = useIntl();

  const [localValue, setLocalValue] = useState<string>();

  // Make sure that the local value and value prop are in sync
  useEffect(() => {
    setLocalValue(value as string);
  }, [value]);

  const { isAvailable, isLoading, setIsAvailable, shouldValidate } =
    useCheckEmailAddressAvailability(
      localValue,
      onAfterValidation,
      validationException,
      asEmployee,
    );

  const endIcon = useMemo(() => {
    if (isLoading) {
      return <Spinner size={20} />;
    } else if (isAvailable === true && shouldValidate) {
      return <SvgIcon name="checkmark" className="h-5 w-5 text-success" />;
    } else if (isAvailable === false && shouldValidate && validationMessageVariant === "error") {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: "users.email-address.availability-warning",
            defaultMessage: "This email address is already in use.",
          })}
        >
          <SvgIcon name="alert-error" className="w-5 text-error" />
        </Tooltip>
      );
    }

    return null;
  }, [intl, isAvailable, isLoading, shouldValidate, validationMessageVariant]);

  return (
    <TextField
      {...props}
      value={value}
      label={
        props.label ??
        intl.formatMessage({ id: "generic.label.email-address", defaultMessage: "Email address" })
      }
      onChange={(newValue) => {
        props.onChange?.(newValue);
        setLocalValue(newValue);
        setIsAvailable(undefined);
        onAfterValidation?.(false);
      }}
      className="test"
      isInvalid={
        (validationMessageVariant === "error" && isAvailable === false && shouldValidate) ||
        (props.isInvalid && isAvailable === false)
      }
      errorMessage={
        validationMessageVariant === "error" && isAvailable === false && shouldValidate
          ? intl.formatMessage({
              id: "users.email-address.availability-warning",
              defaultMessage: "This email address is already in use.",
            })
          : props.errorMessage
      }
      description={
        validationMessageVariant === "warning" &&
        isAvailable === false &&
        shouldValidate &&
        !props.errorMessage
          ? intl.formatMessage({
              id: "users.email-address.availability-warning",
              defaultMessage: "This email address is already in use.",
            })
          : undefined
      }
      endSlot={endIcon || undefined}
    />
  );
};

export default LyraEmailAddressValidatedBaseInput;
