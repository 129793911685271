import { useEffect, useMemo } from "react";

import {
  MultiSearchListField as LyraMultiSearchListField,
  MultiSearchListFieldWithCustomTrigger,
} from "@new-black/lyra";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { uniqBy } from "lodash";

import ErrorBoundary from "components/suite-ui/error-boundary";

import {
  ILocalSearchListFieldGeneratorProps,
  ISearchListFieldGeneratorProps,
  ItemKey,
  MultiSearchListField,
  SpyCallback,
} from "./search-list-field-generator.types";

export const generateMultiSearchListField = <
  SVC extends IEvaServiceDefinition,
  Item extends object,
  IDType extends ItemKey,
>({
  defaultLabel,
  frontendFilter,
  getDescription,
  getItemFromResponse,
  getItemId,
  getLabel,
  initialData,
  selectRenderElements,
  useServiceQuery,
}: ISearchListFieldGeneratorProps<SVC, Item, IDType>) => {
  const MultiSearchListField = ({
    children,
    disableClearLogic,
    errorMessage,
    isDisabled,
    isInvalid,
    isLoading: isOutsideLoading,
    isRequired,
    label,
    name,
    onChange,
    selectRenderElements: componentSelectRenderElements,
    spyCallback,
    values,
    ...props
  }: MultiSearchListField<Item>["Props"] & SpyCallback<Item>) => {
    const [
      [, setInput],
      { data, isFetching: isLoading },
      [request, setRequest],
      isLocalFiltering,
      [configureLoadMoreButton, isLoadMoreLoading, setIsLoadMoreLoading],
    ] = useServiceQuery();

    const responseItems = useMemo(() => getItemFromResponse(data), [data]);

    const items = useMemo(
      () =>
        frontendFilter
          ? uniqBy([...(initialData ?? []), ...(responseItems ?? [])], getItemId).filter(
              frontendFilter,
            )
          : uniqBy([...(initialData ?? []), ...(responseItems ?? [])], getItemId),
      [responseItems],
    );

    useEffect(
      () => spyCallback?.({ listItems: uniqBy([...(values ?? []), ...items], getItemId) }),
      [items, spyCallback, values],
    );

    const loadMoreButtonConfig = useMemo(
      () => configureLoadMoreButton?.(data),
      [configureLoadMoreButton, data],
    );

    if (children !== undefined) {
      return (
        <ErrorBoundary>
          <MultiSearchListFieldWithCustomTrigger<Item>
            name={name}
            isInvalid={isInvalid}
            value={values}
            isDisabled={isDisabled}
            onQueryChange={isLocalFiltering ? undefined : setInput}
            items={items ?? []}
            onChange={onChange}
            label={label ?? defaultLabel ?? ""}
            getItemId={getItemId}
            getLabel={getLabel}
            isLoading={isOutsideLoading || isLoading || isLoadMoreLoading}
            onLoadMore={
              loadMoreButtonConfig?.shouldShowLoadMoreButton ?? false
                ? () => {
                    setIsLoadMoreLoading(true);
                    setRequest(loadMoreButtonConfig?.onLoadMore(request));
                  }
                : undefined
            }
            selectRenderElements={(item) => ({
              label: getLabel(item),
              description: getDescription?.(item),
              ...selectRenderElements?.(item),
              ...componentSelectRenderElements?.(item),
            })}
            {...props}
          >
            {children}
          </MultiSearchListFieldWithCustomTrigger>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <LyraMultiSearchListField<Item>
          name={name}
          isInvalid={isInvalid}
          value={values}
          isDisabled={isDisabled}
          onQueryChange={isLocalFiltering ? undefined : setInput}
          isRequired={isRequired}
          items={items ?? []}
          onChange={onChange}
          errorMessage={errorMessage}
          label={label ?? defaultLabel}
          getItemId={getItemId}
          getLabel={getLabel}
          isLoading={isOutsideLoading || isLoading || isLoadMoreLoading}
          disableClearLogic={disableClearLogic}
          onLoadMore={
            loadMoreButtonConfig?.shouldShowLoadMoreButton ?? false
              ? () => {
                  setIsLoadMoreLoading(true);
                  setRequest(loadMoreButtonConfig?.onLoadMore(request));
                }
              : undefined
          }
          selectRenderElements={(item) => ({
            label: getLabel(item),
            description: getDescription?.(item),
            ...selectRenderElements?.(item),
            ...componentSelectRenderElements?.(item),
          })}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  return MultiSearchListField;
};

export const generateMultiLocalSearchListField = <Item extends object, IDType extends ItemKey>({
  defaultLabel,
  getDescription,
  getItemId,
  getLabel,
  items: itemsArray,
  selectRenderElements,
}: ILocalSearchListFieldGeneratorProps<Item, IDType>) => {
  const MultiSearchListField = ({
    children,
    disableClearLogic,
    errorMessage,
    isDisabled,
    isInvalid,
    isLoading: isOutsideLoading,
    isRequired,
    label,
    name,
    onChange,
    selectRenderElements: componentSelectRenderElements,
    spyCallback,
    values,
    ...props
  }: MultiSearchListField<Item>["Props"] & SpyCallback<Item>) => {
    const items = useMemo(() => uniqBy([...(itemsArray ?? [])], getItemId), []);

    useEffect(
      () => spyCallback?.({ listItems: [...(values ?? []), ...items] }),
      [items, spyCallback, values],
    );

    if (children !== undefined) {
      return (
        <ErrorBoundary>
          <MultiSearchListFieldWithCustomTrigger<Item>
            name={name}
            isInvalid={isInvalid}
            value={values}
            isDisabled={isDisabled}
            items={items ?? []}
            onChange={onChange}
            label={label ?? defaultLabel ?? ""}
            getItemId={getItemId}
            getLabel={getLabel}
            isLoading={isOutsideLoading}
            selectRenderElements={(item) => ({
              label: getLabel(item),
              description: getDescription?.(item),
              ...selectRenderElements?.(item),
              ...componentSelectRenderElements?.(item),
            })}
            {...props}
          >
            {children}
          </MultiSearchListFieldWithCustomTrigger>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <LyraMultiSearchListField<Item>
          name={name}
          isInvalid={isInvalid}
          value={values}
          isDisabled={isDisabled}
          isRequired={isRequired}
          items={items ?? []}
          onChange={onChange}
          errorMessage={errorMessage}
          label={label ?? defaultLabel}
          isLoading={isOutsideLoading}
          getLabel={getLabel}
          getItemId={getItemId}
          disableClearLogic={disableClearLogic}
          selectRenderElements={(item) => ({
            label: getLabel(item),
            description: getDescription?.(item),
            ...selectRenderElements?.(item),
            ...componentSelectRenderElements?.(item),
          })}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  return MultiSearchListField;
};
