import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import { LoyaltyProgramStatus } from "features/loyalty-programs/loyalty-program-form";
import { getLoyaltyProgramStatusTranslation } from "types/translations-enums/loyalty-program-status";

export interface ILoyaltyProgramStatusSelect {
  name?: string;
  errorMessage?: string;
  value?: number;
  onChange: (val?: number) => void;
  isRequired?: boolean;
  hideHintLabel?: boolean;
}

export const LoyaltyProgramStatusSelect = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  name,
  onChange,
  value,
}: ILoyaltyProgramStatusSelect) => {
  const intl = useIntl();

  const translateLoyaltyProgramStatus = useMemo(
    () => getLoyaltyProgramStatusTranslation(intl),
    [intl],
  );

  const items = useMemo(
    () => [
      {
        label: translateLoyaltyProgramStatus(LoyaltyProgramStatus.Inactive as number),
        value: LoyaltyProgramStatus.Inactive,
      },
      {
        label: translateLoyaltyProgramStatus(LoyaltyProgramStatus.Active as number),
        value: LoyaltyProgramStatus.Active,
      },
    ],
    [translateLoyaltyProgramStatus],
  );

  const selectedValue = useMemo(() => items.find((item) => item.value === value), [items, value]);

  return (
    <Select
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      name={name ?? ""}
      value={selectedValue}
      onChange={(newValue) => onChange(newValue?.value)}
      items={items}
      label={intl.formatMessage({ id: "generic.label.status", defaultMessage: "Status" })}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
      selectRenderElements={(item) => ({ label: item.label })}
      getItemId={(item) => item.value}
      getLabel={(item) => item.label}
    />
  );
};
