import { HTMLAttributes } from "react";

import classNames from "classnames";

export const GridLeft = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={classNames("pr-1.5 xl:pr-6 2xl:pr-[3rem]", props.className)} />
);

export const GridRight = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={classNames("pl-1.5 xl:pl-6 2xl:pl-[3rem]", props.className)} />
);
