import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getEnumValuesLoaderQuery,
  serviceQuery: getEnumValuesQuery,
  serviceQueryKeys: getEnumValuesQueryKeys,
  useServiceQueryHook: useGetEnumValuesQuery,
} = createServiceQuery(Core.GetEnumValues);

export const {
  serviceLoaderQuery: getNonEmptyEnumValuesLoaderQuery,
  serviceQuery: getNonEmptyEnumValuesQuery,
  serviceQueryKeys: getNonEmptyEnumValuesQueryKeys,
  useServiceQueryHook: useGetNonEmptyEnumValuesQuery,
} = createServiceQuery(Core.GetEnumValues, true);
