import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const getEnumValuesState = builders.buildServiceFamilyState({
  key: "EnumValuesState",
  service: Core.GetEnumValues,
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "string" || req?.Name === parameter) {
      return req;
    }
    return {
      Name: parameter,
    };
  },
});

export const enumValuesSelector = selectorFamily({
  key: "EnumValuesState:Values",
  get:
    (parameter) =>
    ({ get }) =>
      get(getEnumValuesState.response(parameter))?.Values,
});
