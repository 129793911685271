import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";

import { listTaxCodesQuery } from "models/tax-codes";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

export type ITaxCodeSearchListFieldItem = Pick<EVA.Core.TaxCodeItem, "ID" | "Name">;

export const generateTaxCodeSearchListField = (filters?: Partial<EVA.Core.ListRolesFilter>) => {
  const { SingleIDSearchListField } = SearchListFieldGenerator<
    Core.ListTaxCodes,
    ITaxCodeSearchListFieldItem,
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Result?.Page?.map((ou) => ({ ID: ou.ID, Name: ou.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.tax-code",
      defaultMessage: "Tax code",
    }),
    useItemByID: (id, currentItems) => {
      const currentTaxCode = useMemo(
        () => currentItems?.find((taxCode) => taxCode.ID === id),
        [currentItems, id],
      );

      const { data, isFetching: isLoading } = useQuery({
        ...listTaxCodesQuery(id ? { PageConfig: { Limit: 1, Filter: { ID: id } } } : undefined),
        enabled: !currentTaxCode && !!id,
      });

      return {
        data:
          currentTaxCode ??
          (id && data?.Result?.Page?.[0]
            ? { ID: id, Name: data?.Result?.Page?.[0]?.Name }
            : undefined),
        isLoading,
      };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listTaxCodesQuery,
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...filters },
          },
        },
        getQueryRequest: (req) => (req?.PageConfig?.Filter as any)?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return {
    SingleIDSearchListField,
  };
};
