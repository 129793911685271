import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { CircularProgress } from "@material-ui/core";
import { Button, FieldGroup, SearchListField, SvgIcon, TextField } from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";

import { Autocomplete } from "components/suite-ui/autocomplete";
import IconButton from "components/suite-ui/icon-button";
import Input from "components/suite-ui/input";
import useCurrentUserOU from "features/opening-hours/hooks/use-current-user-ou-hook";
import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { getOrganizationUnitDetailedQuery } from "models/organization-units";
import { emptyStringToUndefined } from "util/helper";

import { getInitialCountryPhoneCode, getPhoneNumberWithoutPrefix } from "./helpers";
import { countriesPhoneNumbers, ICountryPhoneCode } from "./types";
import { usePhoneNumberSelectorHelpers } from "./use-phone-number-selector-helpers";

interface IPhoneNumberSelectorBaseUncontrolledProps {
  name: string;
  defaultValue?: string;
  value?: never;
  onChange?: never;
}

interface IPhoneNumberSelectorBaseControlledProps {
  name?: never;
  defaultValue?: never;
  value?: string;
  onChange: (newValue?: string) => void;
}

interface IPhoneNumberSelectorBaseProps {
  variant?: "material" | "lyra";
  required?: boolean;
  error?: boolean;
  helperText?: string;
  classNames?: { wrapper?: string; prefixAutocomplete?: string; phoneNumberInput?: string };
  labels?: { countryCode?: string; phoneNumber?: string };
  /** Defaults to current user's country ID. */
  initialCountryCode?: string;
  /** Applies only to phone number input. */
  onBlur?: (event: React.FocusEvent<Element, Element>) => void;
}

export type TPhoneNumberSelectorBase = IPhoneNumberSelectorBaseProps &
  (IPhoneNumberSelectorBaseUncontrolledProps | IPhoneNumberSelectorBaseControlledProps);

export const PhoneNumberSelectorBase = ({
  classNames,
  defaultValue,
  error,
  helperText,
  initialCountryCode,
  labels,
  name,
  onBlur,
  onChange,
  required,
  value,
  variant = "material",
}: TPhoneNumberSelectorBase) => {
  const intl = useIntl();
  const currentUserOUID = useCurrentUserOU();

  const initialCountryPhoneCode = useMemo(
    () => getInitialCountryPhoneCode(value ?? defaultValue, initialCountryCode),
    [defaultValue, initialCountryCode, value],
  );

  const [countryPhoneCode, setCountryPhoneCode] = useState<ICountryPhoneCode | undefined>(
    initialCountryPhoneCode as ICountryPhoneCode,
  );
  const hasCountryPhoneCodeChanged = useRef(false);

  // If `initialCountryPhoneCode` is not provided, automatically select country code to current user's country ID
  const { isFetching: isLoading } = useQuery({
    ...getOrganizationUnitDetailedQuery(currentUserOUID ? { ID: currentUserOUID } : undefined, [
      "PhoneNumberSelector:CurrentUserOU",
    ]),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !initialCountryPhoneCode && !hasCountryPhoneCodeChanged.current && !!currentUserOUID,
    onSuccess: (ou) => setCountryPhoneCode(getInitialCountryPhoneCode(undefined, ou?.CountryID)),
  });

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    initialCountryPhoneCode && (value || defaultValue)
      ? getPhoneNumberWithoutPrefix((value ?? defaultValue)!, initialCountryPhoneCode?.phone_code)
      : value ?? defaultValue,
  );

  const localValue = useMemo(() => {
    if (!phoneNumber) {
      return "";
    }

    if (countryPhoneCode?.phone_code) {
      return `${countryPhoneCode.phone_code} ${phoneNumber}`;
    }

    return phoneNumber;
  }, [phoneNumber, countryPhoneCode]);

  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    if (onChange) {
      onChange(localValue || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  const {
    isPhoneNumberValid,
    onAutoFormatNumber,
    phoneNumberPlaceholder,
    suggestedFormattedNumber,
  } = usePhoneNumberSelectorHelpers(debouncedValue, setPhoneNumber, countryPhoneCode);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setPhoneNumber(e.target.value),
    [],
  );

  if (variant === "lyra") {
    return (
      <>
        <div
          className={classnames("flex flex-col items-start gap-2 md:flex-row", classNames?.wrapper)}
        >
          <div className={classnames("w-full md:w-1/3", classNames?.prefixAutocomplete)}>
            <SearchListField
              hideHintLabel
              disableClearLogic
              items={countriesPhoneNumbers}
              value={countryPhoneCode}
              onChange={(selectedItem) => {
                setCountryPhoneCode(selectedItem);
                hasCountryPhoneCodeChanged.current = true;
              }}
              getItemId={(item) => item.country_code}
              getLabel={(item) => `${item.country_code} (${item.phone_code})`}
              selectRenderElements={(item) => ({
                label: `${item.country_name} (${item.phone_code})`,
              })}
              label={
                labels?.countryCode ??
                intl.formatMessage({
                  id: "generic.label.country-code",
                  defaultMessage: "Country code",
                })
              }
              isInvalid={error}
              isDisabled={!countriesPhoneNumbers?.length}
              isLoading={isLoading}
            />
          </div>

          <FieldGroup
            className={classnames(
              "w-full md:w-2/3",
              classNames?.phoneNumberInput,
            )}
          >
            <TextField
              label={
                labels?.phoneNumber ??
                intl.formatMessage({
                  id: "generic.label.phone-number",
                  defaultMessage: "Phone number",
                })
              }
              isRequired={required}
              onBlur={(e) => onBlur?.(e)}
              value={phoneNumber ?? ""}
              onChange={(newValue) => setPhoneNumber(emptyStringToUndefined(newValue))}
              isInvalid={error || (!error && !helperText && !isPhoneNumberValid)}
              errorMessage={
                helperText ??
                (!isPhoneNumberValid
                  ? intl.formatMessage({
                      id: "validation.warning.invalid-phone-number",
                      defaultMessage: "Phone number might not be valid.",
                    })
                  : undefined)
              }
              placeholder={phoneNumberPlaceholder}
              outerEndSlot={suggestedFormattedNumber && onAutoFormatNumber ? (
                <Button
                  variant="icon"
                  onPress={onAutoFormatNumber}
                  tooltip={intl.formatMessage({
                    id: "generic.label.auto-format-number",
                    defaultMessage: "Auto-format number",
                  })}
                >
                  <SvgIcon name="auto-format-phone-number" className="h-4 w-4" />
                </Button>
              ) : undefined}
            />
       
          </FieldGroup>
        </div>

        {name ? <input hidden name={name} value={localValue} readOnly /> : null}
      </>
    );
  }

  return (
    <>
      <div
        className={classnames("flex flex-col items-start gap-5 md:flex-row", classNames?.wrapper)}
      >
        <div className={classnames("w-full md:w-1/3", classNames?.prefixAutocomplete)}>
          <Autocomplete
            items={countriesPhoneNumbers}
            controlledSelectedItem={countryPhoneCode ?? ""}
            handleSelectedItem={(selectedItem) => {
              setCountryPhoneCode(selectedItem);
              hasCountryPhoneCodeChanged.current = true;
            }}
            matchKeys={["phone_code", "country_name", "country_code"]}
            renderOptionValueKey="phone_code"
            renderOptionsSecondaryValueKey="country_name"
            optionIDKey="country_code"
            label={
              labels?.countryCode ??
              intl.formatMessage({
                id: "generic.label.country-code",
                defaultMessage: "Country code",
              })
            }
            helperText={countryPhoneCode?.country_name}
            error={error}
            disabled={!countriesPhoneNumbers?.length}
            endAdornment={isLoading ? <CircularProgress size={20} color="secondary" /> : undefined}
          />
        </div>

        <div className={classnames("w-full md:w-2/3", classNames?.phoneNumberInput)}>
          <Input
            label={
              labels?.phoneNumber ??
              intl.formatMessage({
                id: "generic.label.phone-number",
                defaultMessage: "Phone number",
              })
            }
            required={required}
            onBlur={onBlur}
            value={phoneNumber ?? ""}
            onChange={onInputChange}
            error={error}
            warning={!error && !helperText && !isPhoneNumberValid}
            helperText={
              helperText ??
              (!isPhoneNumberValid
                ? intl.formatMessage({
                    id: "validation.warning.invalid-phone-number",
                    defaultMessage: "Phone number might not be valid.",
                  })
                : undefined)
            }
            placeholder={phoneNumberPlaceholder}
            endIcon={
              suggestedFormattedNumber && onAutoFormatNumber ? (
                <IconButton
                  label={intl.formatMessage({
                    id: "generic.label.auto-format-number",
                    defaultMessage: "Auto-format number",
                  })}
                  onClick={onAutoFormatNumber}
                >
                  <SvgIcon name="auto-format-phone-number" className="h-5 w-5" />
                </IconButton>
              ) : null
            }
          />
        </div>
      </div>

      {name ? <input hidden name={name} value={localValue} readOnly /> : null}
    </>
  );
};
