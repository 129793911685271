import { useIntl } from "react-intl";

import { CardContent, CardHeader, Separator } from "@new-black/lyra";

import LoadingStateBox from "components/shared/loading-state-box";
import { KeyValueRow } from "components/ui/key-value-row";

import { GridLeft, GridRight } from "./destination-helper-components";

export interface IDestinationCustomer {
  customerInfo?: EVA.Core.UserDto;
  customerShippingAddress?: EVA.Core.AddressDto;
}

const DestinationCustomer = ({ customerInfo, customerShippingAddress }: IDestinationCustomer) => {
  const intl = useIntl();

  if (!customerInfo) {
    return <LoadingStateBox limit={5} headerAvailable />;
  }

  if (customerInfo) {
    return (
      <>
        <CardHeader
          title={intl.formatMessage({ id: "generic.label.customer", defaultMessage: "Customer" })}
        />

        <CardContent>
          <div className="flex justify-between">
            <GridLeft className="w-full lg:w-6/12 xl:w-5/12">
              <KeyValueRow
                label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}
                value={customerInfo?.FullName}
                containerClass="gap-x-12"
              />

              <KeyValueRow
                label={intl.formatMessage({
                  id: "generic.label.phone-number",
                  defaultMessage: "Phone number",
                })}
                value={customerInfo?.PhoneNumber}
                containerClass="gap-x-12"
              />
            </GridLeft>
            <GridRight className="w-full lg:w-6/12 xl:w-5/12">
              <KeyValueRow
                label={intl.formatMessage({
                  id: "generic.label.email-address",
                  defaultMessage: "Email address",
                })}
                value={customerInfo?.EmailAddress}
                containerClass="gap-x-12"
              />
            </GridRight>
          </div>
          <Separator className="my-2" />
          <div className="pt-2.5">
            <div className="flex justify-between">
              <GridLeft className="w-full lg:w-6/12 xl:w-5/12">
                {customerShippingAddress?.Address1 && (
                  <KeyValueRow
                    label={intl.formatMessage({
                      id: "generic.label.street",
                      defaultMessage: "Street",
                    })}
                    value={`${customerShippingAddress.Address1} ${customerShippingAddress.HouseNumber}`}
                    containerClass="gap-x-12"
                  />
                )}
                {customerShippingAddress?.Address1 && (
                  <KeyValueRow
                    label={intl.formatMessage({
                      id: "generic.label.address-1",
                      defaultMessage: "Address line 1",
                    })}
                    value={customerShippingAddress.Address1}
                    containerClass="gap-x-12"
                  />
                )}
                {customerShippingAddress?.Address2 && (
                  <KeyValueRow
                    label={intl.formatMessage({
                      id: "generic.label.address-2",
                      defaultMessage: "Address line 2",
                    })}
                    value={customerShippingAddress.Address2}
                    containerClass="gap-x-12"
                  />
                )}
                <KeyValueRow
                  label={intl.formatMessage({ id: "generic.label.city", defaultMessage: "City" })}
                  value={customerShippingAddress?.City}
                  containerClass="gap-x-12"
                />
              </GridLeft>
              <GridRight className="w-full lg:w-6/12 xl:w-5/12">
                <KeyValueRow
                  label={intl.formatMessage({
                    id: "generic.label.postal-code",
                    defaultMessage: "Postal code",
                  })}
                  value={customerShippingAddress?.ZipCode}
                  containerClass="gap-x-12"
                />

                <KeyValueRow
                  label={intl.formatMessage({
                    id: "generic.label.country",
                    defaultMessage: "Country",
                  })}
                  value={customerShippingAddress?.CountryID}
                  containerClass="gap-x-12"
                />
              </GridRight>
            </div>
          </div>
        </CardContent>
      </>
    );
  }
  return null;
};

export default DestinationCustomer;
