import { forwardRef } from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

const UncheckedRadioIcon = forwardRef<SVGSVGElement, SvgIconProps>((props: SvgIconProps, ref) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
    <circle cx="9" cy="9" r="9" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
      fill="black"
      fillOpacity="0.6"
    />
  </SvgIcon>
));

UncheckedRadioIcon.displayName = "UncheckedRadioIcon";

export default UncheckedRadioIcon;
