import { MouseEventHandler, useCallback, useMemo } from "react";

import classNames from "classnames";

import Text, { TextProps } from "components/suite-ui/text";

export interface IControlledCheckboxProps {
  value: boolean;
  defaultChecked?: never;
}

export interface IUncontrolledCheckboxProps {
  value?: never;
  /** Default check value (computed only on first render). */
  defaultChecked?: boolean;
}

export interface ICheckboxProps {
  name: string;
  /** Classes that will be applied on the wrapper div of the checkbox and label. */
  className?: string;
  /** Classes that will be applied only on the label. */
  labelClassName?: string;
  /** Classes that will be applied only on the checkbox. */
  checkboxClassName?: string;
  disabled?: boolean;
  label?: string;
  /** Defaults to `right`. */
  labelPosition?: "top" | "right" | "left";
  labelVariant?: TextProps["variant"];
  /** Callback fired when the value changes. */
  onChange?: (newValue: boolean) => void;
  /**
   * If `true`, the component appears indeterminate. This is just a visual aid and does not influence
   * the checkbox value.
   */
  indeterminate?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

export type CheckboxProps = ICheckboxProps &
  (IControlledCheckboxProps | IUncontrolledCheckboxProps);

const checkboxClassName = (disabled?: boolean, indeterminate?: boolean, className?: string) =>
  classNames(
    "appearance-none",
    "m-0",
    "text-current",
    "focus-visible:ring-1 focus-visible:ring-offset-2 ring-[color:var(--color-primary-3)]",
    "grid items-center shrink-0",
    disabled ? "cursor-not-allowed" : "",
    // unchecked state
    "h-[18px] w-[18px]",
    "rounded-sm border-[0.125rem] border-solid border-[color:var(--color-neutral-9)]",
    disabled
      ? "bg-surface-tertiary"
      : indeterminate
      ? "border-none bg-surface-contrast hover:bg-surface-contrast/80"
      : "bg-transparent hover:bg-surface-contrast/5",
    // checked state
    "before:scale-0 checked:before:scale-100",
    "before:h-[18px] before:w-[18px]",
    "checked:border-none checked:before:rounded-none",
    "checked:before:content-['']",
    disabled
      ? "checked:before:bg-surface-contrast"
      : "checked:before:bg-action-default checked:before:hover:bg-action-hover",
    // indeterminate state
    indeterminate
      ? "[clip-path:path('M_16_0_H_2_c_-1.1_0_-2_0.9_-2_2_v_14_c_0_1.1_0.9_2_2_2_h_14_c_1.1_0_2_-0.9_2_-2_V_2_c_0_-1.1_-0.9_-2_-2_-2_z_m_-2_10_H_4_v_-2_h_10_v_2_z')]"
      : "checked:before:[clip-path:path('M_16_0_H_2_c_-1.11_0_-2_0.9_-2_2_v_14_c_0_1.1_0.89_2_2_2_h_14_c_1.11_0_2_-0.9_2_-2_V_2_c_0_-1.1_-0.89_-2_-2_-2_z_m_-9_14_l_-5_-5_l_1.41_-1.41_L_7_11.17_l_7.59_-7.59_L_16_5_l_-9_9_z')]",
    // class name from props
    className,
  );

const Checkbox = (props: CheckboxProps) => {
  const toggleChecked = useCallback(() => {
    if (props.onChange && !props.disabled) {
      props.onChange(!props.value);
    }
  }, [props]);

  const labelPositionClassName = useMemo(() => {
    switch (props.labelPosition) {
      case "top":
        return "flex-col-reverse gap-1";
      case "left":
        return "flex-row-reverse gap-4";
      case "right":
      default:
        return "gap-4";
    }
  }, [props.labelPosition]);

  return (
    <div
      className={classNames(
        "flex w-fit select-none flex-nowrap items-center",
        props.disabled ? "cursor-not-allowed opacity-40" : "",
        labelPositionClassName,
        props.className,
      )}
      onClick={props.onClick}
      onBlur={props.onBlur}
    >
      <input
        defaultChecked={props.defaultChecked !== undefined ? props.defaultChecked : undefined}
        onChange={toggleChecked}
        type="checkbox"
        name={props.name}
        id={props.name}
        className={checkboxClassName(props.disabled, props.indeterminate, props.checkboxClassName)}
        disabled={props.disabled}
        checked={props.value}
      />

      {props.label ? (
        <label
          htmlFor={props.name}
          className={classNames("flex items-center", props.disabled ? "cursor-not-allowed" : "")}
        >
          <Text as="span" className={props.labelClassName} variant={props.labelVariant}>
            {props.label}
          </Text>
        </label>
      ) : null}
    </div>
  );
};

export default Checkbox;
