import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { searchProductsQuery, useSearchProductsQuery } from "models/products";
import { IProduct } from "types/product";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateSearchProductsLyraSearchListField = ({
  additionalIncludedFields,
}: {
  additionalIncludedFields?: string[];
}) =>
  SearchListFieldGenerator<Core.SearchProducts, IProduct, number>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: searchProductsQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageSize: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          IncludedFields: [
            "product_id",
            "display_value",
            "primary_image.blob",
            "backend_id",
            "custom_id",
            ...(additionalIncludedFields ?? []),
          ],
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: (response?.PageSize ?? 0) < (response?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageSize:
              (request?.PageSize ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
              DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          }),
        }),
        getQueryRequest: (req) => req?.Query,
        setQueryRequest: (req, newValue) => ({
          ...req,
          Query: newValue,
        }),
      }),
    getItemId: (item) => item?.product_id,
    getLabel: (item) => item?.display_value ?? "-",
    getItemFromResponse: (response) => response?.Products,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.product",
      defaultMessage: "Product",
    }),
    useItemByID: (id) => {
      const { data: productBasedOnId, isFetching: isLoading } = useSearchProductsQuery(
        { Filters: { product_id: { Values: id ? [id.toString()] : [] } } },
        {},
      );

      const data = useMemo(() => {
        if (!id) return undefined;

        if (productBasedOnId?.Products?.length !== 1) return undefined;

        return productBasedOnId.Products[0];
      }, [id, productBasedOnId]);

      return {
        data,
        isLoading,
      };
    },
    useItemsByID: (ids) => {
      const { data: productBasedOnId, isFetching: isLoading } = useSearchProductsQuery(
        ids && ids.length
          ? {
              Filters: {
                product_id: {
                  Values: ids,
                },
              },
              IncludedFields: [
                "product_id",
                "display_value",
                "primary_image.blob",
                "backend_id",
                "custom_id",
                ...(additionalIncludedFields ?? []),
              ],
            }
          : undefined,
        {},
      );

      const data = useMemo(() => {
        if (!ids || !ids.length) return undefined;

        return productBasedOnId?.Products;
      }, [ids, productBasedOnId]);

      return {
        data,
        isLoading,
      };
    },
  });
