import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";
import transitions from "@material-ui/core/styles/transitions";
import { Button } from "@new-black/lyra";
import { useMutation, useQuery } from "@tanstack/react-query";

import ErrorBoundary from "components/suite-ui/error-boundary";
import Grid from "components/suite-ui/grid";
import LoadingStateBox from "components/suite-ui/loading-state-box";
import Text from "components/suite-ui/text";
import {
  previewUserInteractionCommunicationQuery,
  previewUserInteractionCommunicationQueryKeys,
} from "models/interaction-logs";
import { saveResource } from "util/save-resource";

import { mutateDownloadUserInteractionCommunicationAttachment } from "./mutations/mutate-download-user-interaction-communication-attachment";
import { InteractionLogEmailPreviewMetadataLine, InteractionLogRawEmailBox } from "./helpers";
import { ResendInteractionEmailModal } from "./resend-interaction-email-modal";

interface IPreviewInteractionLogEmailModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedInteractionLog: EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined;
  setSelectedInteractionLog: Dispatch<
    SetStateAction<EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined>
  >;
  onSuccess?: () => void;
}

export const PreviewInteractionLogEmailModal = ({
  onSuccess,
  open,
  selectedInteractionLog,
  setOpen,
  setSelectedInteractionLog,
}: IPreviewInteractionLogEmailModalProps) => {
  const intl = useIntl();

  const [resendModalOpen, setResendModalOpen] = useState(false);

  const { data: interactionCommunicationPreview, isLoading: isPreviewLoading } = useQuery({
    ...previewUserInteractionCommunicationQuery(
      selectedInteractionLog?.ID ? { ID: selectedInteractionLog.ID } : undefined,
      previewUserInteractionCommunicationQueryKeys.withKey([selectedInteractionLog?.ID]),
    ),
    enabled: !!selectedInteractionLog?.ID,
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => setSelectedInteractionLog(undefined), transitions.duration.leavingScreen);
  }, [setOpen, setSelectedInteractionLog]);

  const handleOpenResendModal = useCallback(() => {
    setOpen(false);
    setResendModalOpen(true);
  }, [setOpen, setResendModalOpen]);

  const dateString = useMemo(
    () =>
      `${intl.formatDate(selectedInteractionLog?.CreationTime)} ${intl.formatTime(
        selectedInteractionLog?.CreationTime,
      )}`,
    [intl, selectedInteractionLog?.CreationTime],
  );

  const emailSender = useMemo(
    () =>
      interactionCommunicationPreview?.FromName
        ? `${interactionCommunicationPreview.FromName} <${interactionCommunicationPreview.FromAddress}>`
        : interactionCommunicationPreview?.FromAddress ?? "",
    [interactionCommunicationPreview?.FromAddress, interactionCommunicationPreview?.FromName],
  );

  const emailRecipient = useMemo(
    () =>
      interactionCommunicationPreview?.ToName
        ? `${interactionCommunicationPreview.ToName} <${interactionCommunicationPreview.ToAddress}>`
        : interactionCommunicationPreview?.ToAddress ?? "",
    [interactionCommunicationPreview?.ToAddress, interactionCommunicationPreview?.ToName],
  );

  const { isLoading, mutateAsync } = useMutation(
    mutateDownloadUserInteractionCommunicationAttachment(),
  );

  const onDownloadAttachment = useCallback(
    (attachmentID: string, attachmentName: string) =>
      selectedInteractionLog?.ID
        ? () => {
            if (selectedInteractionLog?.ID) {
              mutateAsync({ ID: selectedInteractionLog?.ID, AttachmentID: attachmentID }).then(
                (result) => {
                  if (result?.success) {
                    saveResource(result.response?.ResourceInfo.Url, attachmentName, true);
                  }
                },
              );
            }
          }
        : undefined,
    [mutateAsync, selectedInteractionLog?.ID],
  );

  const emailAttachments = useMemo(
    () =>
      interactionCommunicationPreview?.Attachments?.map((attachment) => ({
        label: attachment.Name,
        onClick: onDownloadAttachment(attachment.ID, attachment.Name),
      })),
    [interactionCommunicationPreview?.Attachments, onDownloadAttachment],
  );

  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open && !!selectedInteractionLog}>
        <ErrorBoundary>
          {selectedInteractionLog && interactionCommunicationPreview && !isPreviewLoading ? (
            <>
              <div className="p-5">
                <div className="flex items-center justify-between">
                  <Text variant="h2">
                    {selectedInteractionLog.Subject ?? (
                      <FormattedMessage
                        id="orders.detail.interaction-logs.preview-email-modal.no-subject"
                        defaultMessage="<no subject>"
                      />
                    )}
                  </Text>

                  <Text variant="body2">{dateString}</Text>
                </div>
              </div>
              <div className="px-5 pb-5">
                <ErrorBoundary>
                  <div className="flex flex-col gap-2">
                    <InteractionLogEmailPreviewMetadataLine
                      values={[{ label: emailSender }]}
                      label={intl.formatMessage({
                        id: "generic.label.from",
                        defaultMessage: "From",
                      })}
                    />

                    <InteractionLogEmailPreviewMetadataLine
                      values={[{ label: emailRecipient }]}
                      label={intl.formatMessage({
                        id: "generic.label.to",
                        defaultMessage: "To",
                      })}
                    />

                    {emailAttachments ? (
                      <InteractionLogEmailPreviewMetadataLine
                        values={emailAttachments}
                        label={intl.formatMessage({
                          id: "generic.label.attachments",
                          defaultMessage: "Attachments",
                        })}
                        isLoading={isLoading}
                      />
                    ) : null}
                  </div>
                </ErrorBoundary>
              </div>
              <ErrorBoundary>
                <InteractionLogRawEmailBox
                  p={0}
                  rawHtml={interactionCommunicationPreview.Content}
                />
              </ErrorBoundary>
              <div className="p-5">
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Button variant="secondary" onPress={handleOpenResendModal}>
                      <FormattedMessage
                        id="generic.label.resend-email"
                        defaultMessage="Resend email"
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onPress={handleClose}>
                      <FormattedMessage id="generic.label.ok" defaultMessage="OK" />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            <LoadingStateBox limit={16} />
          )}
        </ErrorBoundary>
      </Dialog>
      <ResendInteractionEmailModal
        open={resendModalOpen}
        setOpen={setResendModalOpen}
        selectedInteractionLog={selectedInteractionLog}
        setSelectedInteractionLog={setSelectedInteractionLog}
        onSuccess={onSuccess}
      />
    </>
  );
};
