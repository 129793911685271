import { useIntl } from "react-intl";

import { getLocalTimeZone, parseAbsoluteToLocal } from "@internationalized/date";
import { DatePicker, DateValue, FieldGroup } from "@new-black/lyra";
import { Field, FieldProps } from "formik";

import { ICustomFieldComponent } from "../types";

interface IMinMaxDate extends Partial<ICustomFieldComponent> {
  fieldType: "date" | "datetime";
}

const MinMaxDate = ({ fieldType }: IMinMaxDate) => {
  const intl = useIntl();

  return (
    <FieldGroup className="flex-row">
      <Field name="MinimumDate">
        {({ field, form, meta }: FieldProps<string | undefined>) => (
          <DatePicker
            label={
              fieldType === "datetime"
                ? intl.formatMessage({
                    id: "generic.label.minimum-date-and-time",
                    defaultMessage: "Minimum date and time",
                  })
                : intl.formatMessage({
                    id: "custom-field.input.minimum-date.label",
                    defaultMessage: "Minimum date",
                  })
            }
            {...field}
            value={field.value ? parseAbsoluteToLocal(field.value) : null}
            onChange={(newValue: DateValue) => {
              form.setFieldValue(field.name, newValue?.toDate(getLocalTimeZone())?.toISOString());
            }}
            granularity={fieldType === "datetime" ? "minute" : "day"}
            isInvalid={meta.touched && !!meta.error}
            errorMessage={meta.touched && !!meta.error ? meta.error : undefined}
          />
        )}
      </Field>
      <Field name="MaximumDate">
        {({ field, form, meta }: FieldProps<string | undefined>) => (
          <DatePicker
            label={
              fieldType === "datetime"
                ? intl.formatMessage({
                    id: "generic.label.maximum-date-and-time",
                    defaultMessage: "Maximum date and time",
                  })
                : intl.formatMessage({
                    id: "custom-field.input.maximum-date.label",
                    defaultMessage: "Maximum date",
                  })
            }
            {...field}
            value={field.value ? parseAbsoluteToLocal(field.value) : null}
            onChange={(newValue: DateValue) => {
              form.setFieldValue(field.name, newValue?.toDate(getLocalTimeZone())?.toISOString());
            }}
            granularity={fieldType === "datetime" ? "minute" : "day"}
            isInvalid={meta.touched && !!meta.error}
            errorMessage={meta.touched && !!meta.error ? meta.error : undefined}
          />
        )}
      </Field>
    </FieldGroup>
  );
};

export default MinMaxDate;
