import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { addSpacingBeforeCapitals } from "util/hooks/use-add-spacing-before-capitals";

import { useEnumValuesQuery } from "../hooks/use-enum-values";

interface LoyaltyProgramOptionsSearchListField {
  value?: number[];
  onChange: (val: number[]) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const LoyaltyProgramOptionsSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: LoyaltyProgramOptionsSearchListField) => {
  const intl = useIntl();

  const { isLoading, valuesArray: items } = useEnumValuesQuery("LoyaltyProgramOptions", false);

  const selectedValue = useMemo<EVA.Core.GetEnumValuesResponse.EnumValue[]>(
    () => items?.filter((item) => value?.includes(item.Value)) ?? [],
    [items, value],
  );

  return (
    <MultiSearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.Value}
      getLabel={(item) => addSpacingBeforeCapitals(item.Name)}
      selectRenderElements={(item) => ({ label: addSpacingBeforeCapitals(item.Name) })}
      value={selectedValue}
      items={items ?? []}
      isLoading={isLoading}
      onChange={(newValue) => onChange(newValue?.map((item) => item.Value) ?? [])}
      label={intl.formatMessage({ id: "generic.label.options", defaultMessage: "Options" })}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
    />
  );
};
