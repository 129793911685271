import { useMemo } from "react";

import { Badge } from "@new-black/lyra";

import { useEnumValuesQuery } from "components/suite-composite/hooks/use-enum-values";

export const LoyaltyProgramGroupDeactivationReasonsBadges = ({ value }: { value?: number }) => {
  const { getName, valuesArray } = useEnumValuesQuery(
    "LoyaltyProgramGroupDeactivationReasons",
    false,
  );

  const deactivationReasons = useMemo(() => {
    if (!valuesArray?.length || value === undefined) return [];

    if (value === 0) {
      return [getName(value)];
    }

    return valuesArray
      ?.filter((entry) => entry.Value !== 0 && (value & entry.Value) === entry.Value)
      .map((entry) => entry.Name);
  }, [getName, value, valuesArray]);

  return (
    <>
      {deactivationReasons.length
        ? deactivationReasons.map((entry, index) => (
            <Badge key={index} variant="transparent">
              {entry}
            </Badge>
          ))
        : null}
    </>
  );
};
