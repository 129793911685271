import { ChangeEvent, FocusEvent, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSelect, Select } from "@new-black/lyra";

import SuiteUISelect from "components/suite-ui/select";

import { useEnumValues } from "../hooks/use-enum-values";

import { IEnumValue } from "./types";
import { useGetUserTypeLabel } from "./use-get-user-type-label";

export { useGetUserTypeLabel } from "./use-get-user-type-label";

export type { IEnumValue as IUserTypeSelectorEnumValue };

interface IUserTypeSelectBaseProps {
  name?: string;
  label?: string;
  multiple?: boolean;
  /** Decides if the `None (0)` option should be included or not */
  includeNone?: boolean;
  value?: number | undefined;
  setValue?: (newValue: number | undefined) => void;
  /** For a given UserType the consumer can decide if it should be included (`true`) or excluded (`false | undefined`)
   *
   * *Note:* In order to include the `None (0)` option, too, the `includeNone?: boolean` prop should also be set
   */
  filterOptions?: (option: IEnumValue<EVA.Core.UserTypes>) => boolean | undefined;
}

export interface IUserTypeSelectProps extends IUserTypeSelectBaseProps {
  error?: boolean;
  helperText?: string;
  onBlur?: (event: FocusEvent<any>) => void;
  required?: boolean;
}

/** Component which handles the selection of user types.
 *
 * This component can be used either with or without Formik.
 *
 * [Users in EVA Docs](https://docs.newblack.io/evauserdocs/docs/users-1)
 *
 * ## Translations
 * This component uses the following translations -
 * please copy them into your translation JSON file:
 * ```json
 * {
 *   "user-type-select.label": "User type",
 *   "generic.label.none": "None",
 *   "user-type-select.enum-value-labels.1": "Employee",
 *   "user-type-select.enum-value-labels.2": "Customer",
 *   "user-type-select.enum-value-labels.4": "Anonymous",
 *   "user-type-select.enum-value-labels.8": "Business",
 *   "user-type-select.enum-value-labels.17": "System",
 *   "user-type-select.enum-value-labels.32": "Migrated",
 *   "user-type-select.enum-value-labels.64": "Debtor",
 *   "user-type-select.enum-value-labels.256": "Limited Trust",
 *   "user-type-select.enum-value-labels.512": "Tester",
 *   "user-type-select.enum-value-labels.1024": "Removed By Request",
 * }
 * ```
 */
export const UserTypeSelect = ({
  error,
  filterOptions,
  helperText,
  includeNone = false,
  label,
  multiple = false,
  name,
  onBlur,
  required = false,
  setValue,
  value,
}: IUserTypeSelectProps) => {
  const intl = useIntl();
  const filteredEnumValues = useFilteredEnumValues({ filterOptions, includeNone });
  const getUserTypeLabel = useGetUserTypeLabel();

  const selectOptions = useMemo(
    () =>
      (filteredEnumValues ?? []).map((enumValue) => ({
        label: getUserTypeLabel(enumValue.value),
        value: enumValue.value,
      })),
    [filteredEnumValues, getUserTypeLabel],
  );

  const selectedSingleUserTypes = useMemo(
    () => selectOptions.find((option) => option.value === value)?.value ?? "",
    [selectOptions, value],
  );

  const selectedMultiUserTypes = useMemo(() => {
    const selectedOptions = selectOptions
      .filter((option) => (option.value & (value ?? 0)) === option.value)
      .map((option) => option.value);
    if (includeNone) {
      return selectedOptions.some((option) => option !== 0)
        ? selectedOptions.filter((option) => option !== 0)
        : [0];
    }
    return selectedOptions;
  }, [includeNone, selectOptions, value]);

  const noneValue = useMemo(() => (includeNone ? 0 : undefined), [includeNone]);

  const handleSingleChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      setValue?.(event.target.value as number);
    },
    [setValue],
  );

  const handleMultiChage = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const newArray = event.target.value as number[];
      setValue?.(newArray.length ? newArray.reduce((prev, curr) => prev | curr, 0) : noneValue);
    },
    [setValue, noneValue],
  );

  return (
    <SuiteUISelect
      error={error}
      onBlur={onBlur}
      multiple={multiple}
      required={required}
      items={selectOptions}
      helpertext={helperText}
      name={name ?? "Composite:UserTypeSelector"}
      onClear={value ? () => setValue?.(noneValue) : undefined}
      onChange={multiple ? handleMultiChage : handleSingleChange}
      value={multiple ? selectedMultiUserTypes : selectedSingleUserTypes}
      label={
        label ??
        intl.formatMessage({
          id: "user-type-select.label",
          defaultMessage: "User type",
        })
      }
    />
  );
};

export interface ILyraUserTypeSelectProps extends IUserTypeSelectBaseProps {
  errorMessage?: string;
  description?: string;
  isRequired?: boolean;
  disableClearLogic?: boolean;
}

export const LyraUserTypeSelect = ({
  description,
  disableClearLogic,
  errorMessage,
  filterOptions,
  includeNone = false,
  isRequired = false,
  label,
  multiple = false,
  setValue,
  value,
}: ILyraUserTypeSelectProps) => {
  const intl = useIntl();
  const filteredEnumValues = useFilteredEnumValues({ filterOptions, includeNone });
  const getUserTypeLabel = useGetUserTypeLabel();

  const selectedSingleUserTypes = useMemo(
    () => filteredEnumValues?.find((option) => option.value === value),
    [filteredEnumValues, value],
  );

  const noneValue = useMemo(
    () => (includeNone ? filteredEnumValues?.find((item) => item.value === 0) : undefined),
    [filteredEnumValues, includeNone],
  );

  const selectedMultiUserTypes = useMemo(() => {
    const selectedOptions = filteredEnumValues?.filter(
      (option) => (option.value & (value ?? 0)) === option.value,
    );

    if (includeNone) {
      return filteredEnumValues?.some((option) => option.value !== 0)
        ? filteredEnumValues?.filter((option) => option.value !== 0)
        : [noneValue!];
    }
    return selectedOptions;
  }, [filteredEnumValues, includeNone, noneValue, value]);

  const handleSingleChange = useCallback(
    (newValue?: IEnumValue<EVA.Core.UserTypes>) => {
      setValue?.(newValue?.value);
    },
    [setValue],
  );

  const handleMultiChange = useCallback(
    (newValue?: IEnumValue<EVA.Core.UserTypes>[]) => {
      setValue?.(
        newValue?.length
          ? newValue?.reduce((prev, curr) => prev | curr.value, 0)
          : noneValue?.value,
      );
    },
    [setValue, noneValue],
  );

  return multiple ? (
    <MultiSelect
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
      description={description}
      isRequired={isRequired}
      items={filteredEnumValues ?? []}
      value={selectedMultiUserTypes ?? []}
      onChange={handleMultiChange}
      getItemId={(item) => item.value}
      getLabel={(item) => getUserTypeLabel(item.value)}
      selectRenderElements={(item) => ({
        label: getUserTypeLabel(item.value),
      })}
      label={
        label ??
        intl.formatMessage({
          id: "user-type-select.label",
          defaultMessage: "User type",
        })
      }
      disableClearLogic={disableClearLogic}
    />
  ) : (
    <Select
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
      description={description}
      isRequired={isRequired}
      items={filteredEnumValues ?? []}
      value={selectedSingleUserTypes}
      onChange={handleSingleChange}
      getItemId={(item) => item.value}
      getLabel={(item) => getUserTypeLabel(item.value)}
      selectRenderElements={(item) => ({
        label: getUserTypeLabel(item.value),
      })}
      label={
        label ??
        intl.formatMessage({
          id: "user-type-select.label",
          defaultMessage: "User type",
        })
      }
      disableClearLogic={disableClearLogic}
    />
  );
};

const useFilteredEnumValues = ({
  filterOptions,
  includeNone,
}: Pick<IUserTypeSelectProps, "filterOptions" | "includeNone">) => {
  const { valuesArray: enumValues } = useEnumValues("UserTypes");
  const filteredEnumValues = useMemo<IEnumValue<EVA.Core.UserTypes>[] | undefined>(
    () =>
      enumValues
        ?.filter((enumValue) => {
          // If the consumer defined its own filtering, then we take that into consideration
          const outsideFilterVerdict = filterOptions
            ? filterOptions({
                key: enumValue.Name,
                value: enumValue.Value,
                // since filterOptions can return undefined, too,
                // we define false as the fallback in case of undefined
              }) ?? false
            : // otherwise all options are included (with some caveats around the None option)
              true;
          // if the None option
          return enumValue.Value === 0
            ? // the includeNone prop decides if we want to include or exclude the None option
              // but it also has to abide by the filter defined by the consumer
              !!includeNone && outsideFilterVerdict
            : // By default every other option is included (if the outside filter permits it)
              outsideFilterVerdict;
        })
        .map((enumValue) => ({ key: enumValue.Name, value: enumValue.Value })),
    [enumValues, filterOptions, includeNone],
  );

  return filteredEnumValues;
};
