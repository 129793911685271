import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

/**
 * Builds service state for the ListOrganizationUnitSets service.
 * It is used in the organization sets chapter overview.
 */
export const organizationUnitSetTypesState = builders.buildServiceState({
  service: Core.GetEnumValues,
  key: "organizationUnitSetTypes",
  requireEmployee: true,
  allowEmptyRequest: false,
  defaultRequest: {
    Name: "OrganizationUnitTypes",
  },
});

export const organizationUnitSetTypes = selector({
  key: "organizationUnitSetTypes:values",
  get: ({ get }) => {
    const response = get(organizationUnitSetTypesState.response);
    // Filter out zero value
    const filtered = response?.Values.filter((item) => item.Value !== 0);

    return filtered;
  },
});
