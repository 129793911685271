import useEnumValues from "util/hooks/use-enum-values";

export const StockLabelsEnumName = "StockLabel";

const useStockLabels = () => {
  const {
    isLoading,
    isLoadingWithoutPreviousResponse: isLoadingWithoutResponse,
    values: stockLabels,
  } = useEnumValues(StockLabelsEnumName);

  return { stockLabels, isLoading, isLoadingWithoutResponse };
};

export default useStockLabels;
