import { useCallback, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { useGetNonEmptyEnumValuesQuery } from "models/enum-values";

import { enumValuesService } from "./use-enum-values.state";

export const USE_ENUM_VALUES_QUERY_KEY = "useEnumValuesQuery";

export function useEnumValuesQuery(name?: string, refetchOnMount?: boolean) {
  const {
    data: getEnumValuesResponse,
    isFetching: isLoading,
    isLoading: isLoadingWithoutPreviousResponse,
  } = useGetNonEmptyEnumValuesQuery(name ? { Name: name } : undefined, {
    loaderKey: name ? [USE_ENUM_VALUES_QUERY_KEY, name] : undefined,
    refetchOnMount,
  });

  const valuesArray = useMemo(() => getEnumValuesResponse?.Values, [getEnumValuesResponse]);

  const helpers = useEnumValuesHelpers(
    valuesArray,
    name !== undefined && isLoading,
    name !== undefined && isLoadingWithoutPreviousResponse,
  );

  return { ...helpers, isLoading, getEnumValuesResponse };
}

export const useEnumValues = (name?: string) => {
  const valuesArray = hooks.useGetState(enumValuesService.response(name));

  const isLoading = hooks.useIsLoading({
    state: enumValuesService.serviceState.response(name),
  });

  const helpers = useEnumValuesHelpers(valuesArray, isLoading);

  return { ...helpers, isLoading };
};

function useEnumValuesHelpers(
  valuesArray: EVA.Core.GetEnumValuesResponse.EnumValue[] | undefined,
  isLoading: boolean,
  isExternallyLoadingWithoutPreviousResponse?: boolean,
) {
  const nameToValueMap = useMemo(
    () =>
      valuesArray?.reduce(
        (acc, curr) => ({ ...acc, [curr.Name]: curr.Value }),
        {} as {
          [K: string]: number;
        },
      ),
    [valuesArray],
  );

  const valueToNameMap = useMemo(
    () =>
      valuesArray?.reduce(
        (acc, curr) => ({ ...acc, [curr.Value]: curr.Name }),
        {} as {
          [K: number]: string;
        },
      ),
    [valuesArray],
  );

  const getName = useCallback(
    (valueToFind: EVA.Core.GetEnumValuesResponse.EnumValue["Value"]) =>
      valuesArray?.find((enumValue) => enumValue.Value === valueToFind)?.Name,
    [valuesArray],
  );

  const getValue = useCallback(
    (nameToFind: EVA.Core.GetEnumValuesResponse.EnumValue["Name"]) =>
      valuesArray?.find((enumValue) => enumValue.Name === nameToFind)?.Value,
    [valuesArray],
  );

  const isLoadingWithoutPreviousResponse = useMemo(
    () =>
      isExternallyLoadingWithoutPreviousResponse
        ? isExternallyLoadingWithoutPreviousResponse
        : isLoading && !valuesArray,
    [isExternallyLoadingWithoutPreviousResponse, isLoading, valuesArray],
  );

  return {
    getName,
    getValue,
    isLoading,
    valuesArray,
    nameToValueMap,
    valueToNameMap,
    isLoadingWithoutPreviousResponse,
  };
}

useEnumValues.service = enumValuesService;
