import { TextFieldProps } from "@new-black/lyra";
import { useField } from "formik";
import { useRecoilState } from "recoil";

import {
  ControlledInputProps,
  FormikInputProps,
  RecoilInputProps,
  UncontrolledInputProps,
} from "components/shared/inputs/inputs.types";

import EmailAddressValidatedBaseInput from "./email-address-validated-base-input";
import { TEmailAddressValidatedInput } from "./email-address-validated-input.types";
import LyraEmailAddressValidatedBaseInput from "./lyra-email-address-validated-base-input";

export const EmailAddressValidatedInput = {
  Controlled: (props: TEmailAddressValidatedInput<ControlledInputProps>) => (
    <EmailAddressValidatedBaseInput {...props} />
  ),
  Uncontrolled: (props: TEmailAddressValidatedInput<UncontrolledInputProps>) => (
    <EmailAddressValidatedBaseInput {...props} />
  ),
  /** Same functionality as `FormikInput` */
  Formik: (props: TEmailAddressValidatedInput<FormikInputProps>) => {
    const [field, meta] = useField<string | undefined>(props.name ?? "EmailAddress");

    return (
      <EmailAddressValidatedBaseInput
        {...props}
        {...field}
        error={props.error || (!!meta.error && meta.touched)}
        helperText={meta.error && meta.touched ? meta.error : props.helperText}
        inputProps={{
          ...props.inputProps,
          onBlur: (event) => {
            props.inputProps?.onBlur?.(event);
            props.onBlur?.(event);
          },
        }}
      />
    );
  },
  Recoil: (props: TEmailAddressValidatedInput<RecoilInputProps>) => {
    const [value, setValue] = useRecoilState(props.recoilState);

    return (
      <EmailAddressValidatedBaseInput
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  },
};

export const LyraEmailAddressValidatedInput = {
  Controlled: (props: Omit<TEmailAddressValidatedInput<TextFieldProps>, "defaultValue">) => (
    <LyraEmailAddressValidatedBaseInput {...props} />
  ),
  Uncontrolled: (
    props: Omit<TEmailAddressValidatedInput<TextFieldProps>, "value" | "onChange">,
  ) => <LyraEmailAddressValidatedBaseInput {...props} />,
};
