import { NumberField, SearchField } from "@new-black/lyra";
import { useField } from "formik";

import { FormikInput, FormikNumberInput } from "components/suite-composite/formik-inputs";
import Input from "components/suite-ui/input";
import NumberInput from "components/suite-ui/number-input";
import useRecoilDebouncedValue from "hooks/suite-react-hooks/use-recoil-debounced-value";
import { emptyStringToUndefined, numberOrNullToUndefined } from "util/helper";

import { EndIcon } from "./inputs.helper-components";
import {
  ControlledInputProps,
  ControlledNumberInputProps,
  FormikInputProps,
  FormikNumberInputProps,
  IControlledLyraNumberFieldProps,
  IControlledLyraSearchFieldProps,
  IFormikLyraNumberFieldProps,
  IFormikLyraSearchFieldProps,
  IInputController,
  ILyraNumberFieldController,
  ILyraSearchFieldController,
  INumberInputController,
  IRecoilLyraNumberFieldProps,
  IRecoilLyraSearchFieldProps,
  IUncontrolledLyraNumberFieldProps,
  IUncontrolledLyraSearchFieldProps,
  RecoilInputProps,
  RecoilNumberInputProps,
  UncontrolledInputProps,
  UncontrolledNumberInputProps,
} from "./inputs.types";

export function getLyraSearchField(props: ILyraSearchFieldController) {
  const Controlled = (inputProps: IControlledLyraSearchFieldProps) => (
    <SearchField
      {...props}
      {...inputProps}
      onClear={() => inputProps.onChange(undefined)}
      onChange={(newVal) => inputProps.onChange(emptyStringToUndefined(newVal))}
    />
  );

  const Uncontrolled = (inputProps: IUncontrolledLyraSearchFieldProps) => (
    <SearchField {...props} {...inputProps} />
  );

  function Formik(inputProps: IFormikLyraSearchFieldProps) {
    const [field, meta, helpers] = useField<string | undefined>(inputProps.name);
    return (
      <SearchField
        {...props}
        {...inputProps}
        {...field}
        value={field.value}
        isInvalid={meta.touched && !!meta.error}
        onClear={() => helpers.setValue(undefined)}
        onChange={(newVal) => helpers.setValue(emptyStringToUndefined(newVal))}
        errorMessage={meta.error && meta.touched ? meta.error : inputProps.errorMessage}
        onBlur={(event) => {
          props.onBlur?.(event);
          inputProps.onBlur?.(event);
        }}
      />
    );
  }

  function Recoil(inputProps: IRecoilLyraSearchFieldProps) {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(inputProps.recoilState);
    return (
      <Controlled
        {...inputProps}
        value={recoilValue ?? ""}
        onClear={() => setRecoilValue(undefined)}
        onChange={(newVal) => setRecoilValue(emptyStringToUndefined(newVal))}
      />
    );
  }

  return { Controlled, Uncontrolled, Formik, Recoil };
}

export const getStringInput = (props: IInputController) => {
  const Controlled = ({ enableClearIcon, endIcon, ...inputProps }: ControlledInputProps) => (
    <Input
      {...props}
      {...inputProps}
      endIcon={
        <EndIcon
          enableClearIcon={enableClearIcon}
          endIcon={endIcon}
          value={inputProps.value}
          setValue={inputProps.onChange}
        />
      }
    />
  );
  const Uncontrolled = (inputProps: UncontrolledInputProps) => <Input {...props} {...inputProps} />;

  const Formik = ({ ...inputProps }: FormikInputProps) => (
    <FormikInput {...props} {...inputProps} />
  );

  const Recoil = ({ enableClearIcon, endIcon, recoilState, ...inputProps }: RecoilInputProps) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);

    return (
      <Controlled
        value={recoilValue ?? ""}
        onChange={(event) => setRecoilValue(event.target.value || undefined)}
        endIcon={
          <EndIcon
            enableClearIcon={enableClearIcon}
            value={recoilValue}
            setValue={setRecoilValue}
            endIcon={endIcon}
          />
        }
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};

export function getLyraNumberField(props: ILyraNumberFieldController) {
  const Controlled = (inputProps: IControlledLyraNumberFieldProps) => (
    <NumberField
      {...props}
      {...inputProps}
      value={inputProps.value ?? null}
      onChange={(newVal) => inputProps.onChange(numberOrNullToUndefined(newVal))}
    />
  );

  const Uncontrolled = (inputProps: IUncontrolledLyraNumberFieldProps) => (
    <NumberField {...props} {...inputProps} />
  );

  function Formik(inputProps: IFormikLyraNumberFieldProps) {
    const [field, meta, helpers] = useField<number | undefined>(inputProps.name);
    return (
      <NumberField
        {...props}
        {...inputProps}
        {...field}
        value={field.value ?? null}
        isInvalid={meta.touched && !!meta.error}
        onChange={(newVal) => helpers.setValue(numberOrNullToUndefined(newVal))}
        errorMessage={meta.error && meta.touched ? meta.error : inputProps.errorMessage}
        onBlur={(event) => {
          props.onBlur?.(event);
          inputProps.onBlur?.(event);
        }}
      />
    );
  }

  function Recoil(inputProps: IRecoilLyraNumberFieldProps) {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(inputProps.recoilState);
    return (
      <Controlled
        {...inputProps}
        value={recoilValue}
        onChange={(newVal) => setRecoilValue(newVal)}
      />
    );
  }

  return { Controlled, Uncontrolled, Formik, Recoil };
}

export const getNumberInput = (props: INumberInputController) => {
  const Controlled = ({ enableClearIcon, endIcon, ...inputProps }: ControlledNumberInputProps) => (
    <NumberInput
      {...props}
      {...inputProps}
      endIcon={
        <EndIcon
          enableClearIcon={enableClearIcon}
          endIcon={endIcon}
          value={inputProps.value}
          setValue={inputProps.onChange}
        />
      }
    />
  );
  const Uncontrolled = (inputProps: UncontrolledNumberInputProps) => (
    <NumberInput {...props} {...inputProps} />
  );

  const Formik = ({ ...inputProps }: FormikNumberInputProps) => (
    <FormikNumberInput {...props} {...inputProps} />
  );

  const Recoil = ({
    enableClearIcon,
    endIcon,
    recoilState,
    ...inputProps
  }: RecoilNumberInputProps) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);

    return (
      <Controlled
        value={recoilValue ?? ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setRecoilValue(event.target.value ? parseInt(event.target.value, 10) : undefined)
        }
        endIcon={
          <EndIcon
            enableClearIcon={enableClearIcon}
            value={recoilValue}
            setValue={setRecoilValue}
            endIcon={endIcon}
          />
        }
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
