import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

export const orderLineTypesServiceState = builders.buildServiceState({
  service: Core.GetEnumValues,
  key: "GetEnumValues:OrderLineTypes",
  allowEmptyRequest: true,
  requireLoggedIn: true,
  requireEmployee: true,
  defaultRequest: {
    Name: "OrderLineTypes",
  },
});

export const orderLineTypesResultSelector = selector({
  key: "GetEnumValues:OrderLineTypes:Result",
  get: ({ get }) => get(orderLineTypesServiceState.response)?.Values,
});
