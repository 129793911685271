import { Core } from "@springtree/eva-services-core";

import { getEnumValuesQuery } from "models/enum-values";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { ONE_HOUR } from "util/base-values";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const EnumAutocompleteGenerator = (
  enumName: string,
  defaultLabel?: string,
  excludeNone = true,
) =>
  AutocompleteGenerator<Core.GetEnumValues, { Value: number; Name: string }>({
    idKey: "Value",
    defaultLabel,
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.Values?.map((enumValue) => ({ Value: enumValue.Value, Name: enumValue.Name }))?.filter(
        (item) => (excludeNone ? item.Value !== 0 : true),
      ),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        staleTime: ONE_HOUR,
        refetchOnFocus: false,
        query: getEnumValuesQuery,
        initialRequest: { Name: enumName },
      }),
  });

export const EnumLyraSearchListFieldGenerator = (
  enumName: string,
  defaultLabel?: string,
  excludeNone = true,
) =>
  SearchListFieldGenerator<Core.GetEnumValues, { Value: number; Name: string }, number>({
    getItemId: (item) => item.Value,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) =>
      resp?.Values?.map((enumValue) => ({ Value: enumValue.Value, Name: enumValue.Name }))?.filter(
        (item) => (excludeNone ? item.Value !== 0 : true),
      ),
    defaultLabel,
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        staleTime: ONE_HOUR,
        refetchOnFocus: false,
        query: getEnumValuesQuery,
        initialRequest: { Name: enumName },
      }),
    useItemByID: (id, items) => {
      const item = items?.find((item) => item.Value === id);

      return {
        data: id ? item : undefined,
        isLoading: false,
      };
    },
  });
