import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { noWait, selector } from "recoil";

export const orderPropertiesListState = builders.buildServiceState({
  requireEmployee: true,
  key: "orderProperties",
  allowEmptyRequest: true,
  service: Core.GetEnumValues,
  defaultRequest: { Name: "OrderProperties" },
});

export const orderPropertiesListSelector = selector<
  EVA.Core.GetEnumValuesResponse.EnumValue[] | undefined
>({
  key: "orderProperties:selector",
  get: ({ get }) => {
    const responseLoadable = get(noWait(orderPropertiesListState.response));
    return responseLoadable.contents?.Values;
  },
});
