import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Badge } from "@new-black/lyra";

import { useEnumValuesQuery } from "components/suite-composite/hooks/use-enum-values";
import { getUserBudgetDeductionTranslator } from "types/translations-enums/user-budget-deduction";

export const LoyaltyProgramUserBudgetDeductionBadge = ({ value }: { value: number }) => {
  const intl = useIntl();

  const { getName, valuesArray } = useEnumValuesQuery("UserBudgetDeduction", false);

  const getUserBudgetDeductionTranslation = useCallback(
    (value: number) => getUserBudgetDeductionTranslator(intl)(value) ?? getName(value),
    [getName, intl],
  );

  const allOption = useMemo(
    () => valuesArray?.reduce((accumulator, current) => accumulator | current.Value, 0),
    [valuesArray],
  );

  const items = useMemo(
    () => valuesArray?.filter((item) => item.Value !== allOption),
    [allOption, valuesArray],
  );

  const userBudgetDeduction = useMemo(() => {
    if (!items?.length) return [];

    if (value === 0) {
      return [getUserBudgetDeductionTranslation(value)];
    }

    return items
      ?.filter((entry) => entry.Value !== 0 && (entry.Value & value) === entry.Value)
      .map((entry) => entry.Name);
  }, [getUserBudgetDeductionTranslation, items, value]);

  return (
    <>
      {userBudgetDeduction.length
        ? userBudgetDeduction.map((entry, index) => (
            <Badge key={index} variant="transparent">
              {entry}
            </Badge>
          ))
        : null}
    </>
  );
};
