import { ComponentProps, useEffect, useState } from "react";

import { Core } from "@springtree/eva-services-core";

import { BarcodeFormat } from "types/barcode";
import { mutate } from "util/mutate";

import { getBarcodeImgProps, getBarcodeSize } from "./helpers";
import { IGetBarcodesOptions } from "./types";

/** Async function that generates the img props for a list of barcodes generated by the new [Barcode service](https://dora.on-eva.io/Barcode).
 *
 * @param codes The codes to generate the barcodes for.
 * @param options see {@link IGetBarcodesOptions}
 */
export const getBarcodes = async (
  codes: string[] | undefined,
  { alt, Format = BarcodeFormat.QR, Height, Width, ...rest }: IGetBarcodesOptions,
): Promise<ComponentProps<"img">[]> => {
  const { height, width } = getBarcodeSize(Width, Height);
  const fetchBarcode = mutate({
    service: Core.Barcode,
    disabledNotifications: true,
    disableErrorNotification: true,
  });
  const queries = codes
    ?.filter((Code) => Code !== undefined)
    .map((Code) =>
      fetchBarcode({
        Code,
        Width: width,
        Height: height,
        Format: Format as number,
        ...rest,
      }),
    );
  const results = await Promise.all(queries ?? []);
  const barcodeImgProps = results
    .map((result) => getBarcodeImgProps(result?.response, { alt, width, height }))
    .filter((src): src is ComponentProps<"img"> => src !== undefined);
  return barcodeImgProps;
};

/** Hook that generates the img props for a list of barcodes generated by the new [Barcode service](https://dora.on-eva.io/Barcode).
 *
 * @param codes The codes to generate the barcodes for.
 * @param options see {@link IGetBarcodesOptions}
 */
export const useBarcodes = (
  codes: string[] | undefined,
  {
    alt,
    BackgroundHex,
    BlobID,
    ForegroundHex,
    Format,
    Height,
    NoMargin,
    NoText,
    Width,
  }: IGetBarcodesOptions,
) => {
  const [barcodeImgProps, setBarcodeImgProps] = useState<ComponentProps<"img">[] | undefined>(
    undefined,
  );

  useEffect(() => {
    getBarcodes(codes, {
      alt,
      Width,
      Height,
      Format,
      BlobID,
      NoText,
      NoMargin,
      BackgroundHex,
      ForegroundHex,
    }).then(setBarcodeImgProps);
  }, [BackgroundHex, BlobID, ForegroundHex, Format, Height, NoMargin, NoText, Width, alt, codes]);

  return barcodeImgProps;
};
