import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { orderLineTypesResultSelector } from ".";

const useOrderLineTypes = () => {
  const orderLineTypes = hooks.useGetState(orderLineTypesResultSelector);
  const isLoading = hooks.useIsLoading({ state: orderLineTypesResultSelector });

  const isLoadingWithoutResponse = useMemo(
    () => isLoading && !orderLineTypes,
    [isLoading, orderLineTypes],
  );

  return { orderLineTypes, isLoading, isLoadingWithoutResponse };
};

export default useOrderLineTypes;
