enum ProductTypes {
  None = 0,
  Stock = 1,
  Insurance = 2,
  Marketing = 4,
  GiftCard = 8,
  Service = 16,
  GreetingCard = 32,
  CustomPricing = 64,
  External = 128,
  OrderCosts = 256,
  SystemGenerated = 512,
  ProductSet = 1024,
  BundleProduct = 2048,
  VirtualProduct = 4096,
  MadeToOrder = 8192,
  Configurable = 16384,
  SupplierProduct = 32768,
  Template = 65536,
  SecondChance = 131072,
  NotReturnable = 262144,
}

export default ProductTypes;
