import { useCallback } from "react";

import { clone } from "lodash";

interface EnumValue {
  key: string;
  value: number;
}

const useMapToEnumValues = (enumArray: { key: string; value: number }[] | undefined) => {
  const mapToEnumValues = useCallback(
    (value: number) => {
      const output: Array<EnumValue> | undefined = [];
      const clonedEnums: Array<EnumValue> | undefined = clone(enumArray);
      const sortedEnumValues: Array<EnumValue> | undefined = clonedEnums?.sort(
        (a, b) => b.value - a.value,
      );

      if (sortedEnumValues) {
        while (value > 0) {
          sortedEnumValues
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .forEach((v) => {
              if (v.value <= value) {
                value -= v.value;
                output.unshift(v);
              }
            });
        }
      }
      return output.map((enumItem) => enumItem.value);
    },
    [enumArray],
  );

  return mapToEnumValues;
};

export default useMapToEnumValues;
