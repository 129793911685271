import { useMemo } from "react";

import { PIM } from "@springtree/eva-services-pim";

import {
  listProductPropertyTypesQuery,
  useListProductPropertyTypesQuery,
} from "models/product-search-properties";
import {
  ProductPropertyTypeSearchTypeMatchingMethod,
  ProductPropertyTypeSearchTypes,
} from "types/enums";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "util/lyra-search-list-field-generator/search-list-field-generator.types";

export type TProductSearchPropertySearchListFieldItem = {
  ID: string;
  Name: string;
  DataType?: EVA.PIM.ProductPropertyTypeDataTypes;
};

export const generateLyraProductSearchPropertySearchListField = ({
  frontendFilter,
}: Partial<
  Pick<
    ISearchListFieldGeneratorProps<
      PIM.ListProductPropertyTypes,
      TProductSearchPropertySearchListFieldItem,
      string
    >,
    "frontendFilter"
  >
>) =>
  SearchListFieldGenerator<
    PIM.ListProductPropertyTypes,
    TProductSearchPropertySearchListFieldItem,
    string
  >({
    frontendFilter,
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    useItemByID: useProductSearchPropertySLFItemById,
    useItemsByID: useProductSearchPropertiesSLFItemById,
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((item) => ({
        ID: item.ID,
        Name: item.ID,
        DataType: item.DataType,
      })),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listProductPropertyTypesQuery,
        queryKey: ["ListProductPropertyTypes:SearchListField"],
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              SearchType:
                ProductPropertyTypeSearchTypes.Keyword + ProductPropertyTypeSearchTypes.Text,
              SearchTypeMatchingMethod:
                ProductPropertyTypeSearchTypeMatchingMethod.MatchesAtLeastOne as number,
            },
          },
        },
        configureLoadMoreButton: (resp) => ({
          shouldShowLoadMoreButton:
            (resp?.Result.Total ?? 0) > (resp?.Result.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit:
                (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        getQueryRequest: (req) => req?.PageConfig?.Filter?.ID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              ID: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

export function useProductSearchPropertiesById(
  ids: string[] | undefined,
  currentListItems?: TProductSearchPropertySearchListFieldItem[],
) {
  const itemsFromList = useMemo(
    () =>
      ids
        ?.map((id) => currentListItems?.find((item) => item.ID === id))
        .filter((item): item is NonNullable<typeof item> => !!item),
    [currentListItems, ids],
  );

  const { data, isFetching: isLoading } = useListProductPropertyTypesQuery(
    ids !== undefined && ids.length && (!itemsFromList || itemsFromList.length !== ids.length)
      ? { PageConfig: { Limit: ids.length, Filter: { IDs: ids } } }
      : undefined,
    { loaderKey: ids !== undefined && ids.length ? ids : undefined },
  );

  const productSearchPropertyItems = useMemo(
    () =>
      ids
        ?.map((id) => data?.Result?.Page?.find((item) => item.ID === id))
        .filter((item): item is NonNullable<typeof item> => !!item),
    [data?.Result?.Page, ids],
  );

  return {
    itemsFromList,
    productSearchPropertyItems,
    isLoading: ids !== undefined && ids.length && !itemsFromList ? isLoading : false,
  };
}

export function useProductSearchPropertyById(
  id: string | undefined,
  currentListItems?: TProductSearchPropertySearchListFieldItem[],
) {
  // Check if item is already in list, so we prevent the request if it's not necessary
  const itemFromList = useMemo(
    () => currentListItems?.find((item) => item.ID === id),
    [currentListItems, id],
  );

  const { data, isFetching: isLoading } = useListProductPropertyTypesQuery(
    id !== undefined && !itemFromList
      ? { PageConfig: { Limit: 1, Filter: { IDs: [id] } } }
      : // The ID filter is fuzzy, so we can't really be sure if the item is in the list or not
        // Instead we use the IDs filter instead, which only considers exact matches (which is what we actually want in this case)
        undefined,
    { loaderKey: id !== undefined ? [id] : undefined },
  );

  const productSearchPropertyItem = useMemo(
    () => data?.Result?.Page?.find((item) => item.ID === id),
    [data?.Result?.Page, id],
  );

  return {
    itemFromList,
    productSearchPropertyItem,
    isLoading: id !== undefined && !itemFromList ? isLoading : false,
  };
}

function useProductSearchPropertySLFItemById(
  id: string | undefined,
  currentListItems: TProductSearchPropertySearchListFieldItem[] | undefined,
) {
  const { isLoading, itemFromList, productSearchPropertyItem } = useProductSearchPropertyById(
    id,
    currentListItems,
  );

  const mappedProductSearchProperty = useMemo(
    () =>
      productSearchPropertyItem
        ? {
            ID: productSearchPropertyItem.ID,
            Name: productSearchPropertyItem.ID,
            DataType: productSearchPropertyItem.DataType,
          }
        : undefined,
    [productSearchPropertyItem],
  );

  return { data: itemFromList ?? mappedProductSearchProperty, isLoading };
}

function useProductSearchPropertiesSLFItemById(
  ids: string[] | undefined,
  currentListItems: TProductSearchPropertySearchListFieldItem[] | undefined,
) {
  const { isLoading, itemsFromList, productSearchPropertyItems } = useProductSearchPropertiesById(
    ids,
    currentListItems,
  );

  const mappedProductSearchProperty = useMemo(
    () =>
      ids
        ?.map((id) => {
          const productSearchPropertyItem =
            itemsFromList?.find((item) => item.ID === id) ??
            productSearchPropertyItems?.find((item) => item.ID === id);
          return productSearchPropertyItem
            ? {
                ID: productSearchPropertyItem.ID,
                Name: productSearchPropertyItem.ID,
                DataType: productSearchPropertyItem.DataType,
              }
            : undefined;
        })
        .filter((item): item is NonNullable<typeof item> => !!item),
    [ids, itemsFromList, productSearchPropertyItems],
  );

  return { data: mappedProductSearchProperty, isLoading };
}
