import ky from "ky";
import _, { clone } from "lodash";
import moment from "moment";

import { intlAccessor } from "./intl-accessor";

/**
 * Utility method to prevent double slashes in a URL
 */
export function preventDoubleSlashes(url: string) {
  return url.replace(/([^:]\/)\/+/g, "$1");
}

export const removeUndefinedValues = (obj: any): any => {
  const result = _(obj)
    .pickBy(_.isObject) // pick objects only
    .mapValues(removeUndefinedValues) // call only for object values
    .assign(_.omitBy(obj, _.isObject)) // assign back primitive values
    .omitBy(_.isUndefined) // remove undefined props
    .value();

  return result;
};

export const removeEmptyObjects = (obj: any): any =>
  _(obj)
    .pickBy(_.isObject) // pick objects only
    .mapValues(removeEmptyObjects) // call only for object values
    .omitBy(_.isEmpty) // remove empty objects
    .assign(_.omitBy(obj, _.isObject)) // assign back primitive values
    .value();

export const removeUndefinedArrayValues = <T>(arr: (T | undefined)[]): T[] =>
  arr.filter((v): v is T => v !== undefined);

export const enumValueToEnumValuesResponse = (
  value: number,
  enums: EVA.Core.GetEnumValuesResponse,
) => {
  const output: EVA.Core.GetEnumValuesResponse.EnumValue[] = [];
  const enumValues: EVA.Core.GetEnumValuesResponse.EnumValue[] = clone(enums.Values);
  const sortedEnumValues: EVA.Core.GetEnumValuesResponse.EnumValue[] = enumValues.sort(
    (a, b) => b.Value - a.Value,
  );

  while (value > 0) {
    sortedEnumValues
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      .forEach((v) => {
        if (v.Value <= value) {
          value -= v.Value;
          output.unshift(v);
        }
      });
  }
  return output;
};

export const handleKyError = async (e: Error) => {
  if (e.name === "HTTPError") {
    const httpError = e as ky.HTTPError;
    const error = await httpError.response.clone().json();
    console.error(`[Error: ${e.name}]:`, error);
    return error.Error.Message as string;
  } else {
    console.error(`[Error: ${e.name}]:`, e);
    return intlAccessor.formatMessage({
      id: "generic.message.something-went-wrong",
      defaultMessage: "Something went wrong.",
    });
  }
};

export const readFileAsDataUrl = (file: File) =>
  new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const buf = reader.result;
      if (buf) {
        const decoder = new TextDecoder("utf-8");
        const fileTextContent = typeof buf === "string" ? buf : decoder.decode(buf);
        const encodedText = fileTextContent.replace("data:", "").replace(/^.+,/, "");
        resolve(encodedText);
      }
      resolve(undefined);
    };
    reader.onerror = (error) => reject(error);
  });

/** Logic taken from the Old Admin code: https://github.com/new-black/eva-admin/blob/4c3df6bac4dd59eabf1ffc3ce404971a1a9a371f/src/core/components/file-upload.component.ts#L64-L88 */
export const readFileAsBase64String = (file: File) =>
  new Promise<string | undefined>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const result = fileReader.result as string;
      const idx = result.indexOf("base64,");
      resolve(result.substring(idx + "base64,".length));
    };
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsDataURL(file);
  });

export const base64EncodeFileData = (fileData: string | ArrayBuffer | null | undefined) =>
  /base64,(.+)/.exec(fileData as any)?.[1];

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

// helper to add an event listener to the window/document
//
export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
  }
}

// helper to remove an event listener from the window/document
//
export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
  }
}

export const isBrowser = typeof window !== "undefined";

export const isNavigator = typeof navigator !== "undefined";

export function checkEmailValid(value: string) {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
}

export const numberOrNullToUndefined = (value: number | null) =>
  value === null ? undefined : value;

export const emptyStringToUndefined = <T extends string>(str?: T) => (str === "" ? undefined : str);

export const isEmptyStringOrUndefined = (value: string | undefined) => {
  if (value === "" || value === undefined) {
    return true;
  }
  return false;
};

export const removeZeroWidthCharacters = (value?: string) =>
  value?.replace(/[\u200B-\u200D\uFEFF]/g, "");

export const getUrlWithSearchParams = (url: string, searchParams: { [key: string]: string }) => {
  const urlSearchParams = new URLSearchParams();

  Object.entries(searchParams).forEach(([key, value]) => urlSearchParams.set(key, value));

  return url.concat(`?${urlSearchParams.toString()}`);
};

export const emptyArrayToUndefined = <T>(arr?: T[]) => (arr?.length === 0 ? undefined : arr);

export const emptyObjectToUndefined = <T>(obj?: T) =>
  Object.keys(obj || {}).length === 0 ? undefined : obj;

/**
 * Returns the mapped sum of the values of all items in an array that satisfy a given condition.
 * @template T The type of items in the array.
 * @param {T[] | undefined} arr The array to search.
 * @param {(item: T) => boolean} filter The condition that an item must satisfy to be included in the sum.
 * @param {(item: T) => number} itemValue A function that returns the value of an item to be included in the sum.
 * @param {number} [start=0] The initial value of the sum.
 * @returns {number | undefined} The sum of the values of all items in the array that satisfy the condition.
 */
export const sumIf = <T>(
  arr: T[] | undefined,
  filter: (item: T) => boolean,
  itemValue: (item: T) => number,
  start = 0,
): number | undefined =>
  arr?.reduce(
    (accumulator, item) => (filter(item) ? accumulator + itemValue(item) : accumulator),
    start,
  );

/** Determines if the text should be white or black based on the calculated contrast of a custom color
 * Sources:
 *   - https://stackoverflow.com/a/11868398
 *   - https://24ways.org/2010/calculating-color-contrast
 *   - https://en.wikipedia.org/wiki/YIQ
 *
 * @param hex The hex code of the color
 * @returns "black" or "white"
 */
export function getTextColorForCustomColor(
  hex: string,
  options = { whiteValue: "white", blackValue: "black" },
) {
  const [r, g, b] = [
    parseInt(hex.substring(1, 3), 16),
    parseInt(hex.substring(3, 5), 16),
    parseInt(hex.substring(5, 7), 16),
  ];
  const yiqContrastValue = (r * 299 + g * 587 + b * 114) / 1000;
  return yiqContrastValue >= 128 ? options.blackValue : options.whiteValue;
}

/** Replace regular line breaks with escaped line breaks so they can be easier to explicitly identify after serialization */
export const getFormattedContentWithLineBreaks = (value?: string) =>
  value?.replaceAll(/(?:\r\n|\r|\n)/g, "\\n");

/** Type that describes a string where the first character is uppercased */
export type CapitalString<T extends string> = T extends `${infer Head}${infer Tail}`
  ? `${Uppercase<Head>}${Tail}`
  : T;

/** Capitalizes the first character of a string */
export const capitalizeString = <T extends string>(str: T): CapitalString<T> =>
  `${str[0].toUpperCase()}${str.slice(1)}` as CapitalString<T>;

/** Remove trailing slashes & spaces from the given text
 *
 * The recursive function starts from the end of the string
 * and removes the last character if it is a slash or a space.
 * The recursion stops when the string is empty
 * or the last character is neither a slash, nor a space.
 *
 * @param text - The text to remove the trailing slashes and spaces from
 * @returns The text without trailing slashes or spaces
 */
export function removeTrailingSlashAndTrim(text: string): string {
  if (text === "") {
    return text;
  }
  if (text.endsWith(" ")) {
    return removeTrailingSlashAndTrim(text.trim());
  }
  if (text.endsWith("/")) {
    return removeTrailingSlashAndTrim(text.slice(0, -1));
  }
  return text;
}

/**
 * Selects the next item in a round-robin fashion from an array of items.
 *
 * @template T - The type of the items in the array.
 * @param {T[]} items - The array of items.
 * @param {number} currentIndex - The index of the current item.
 * @returns {T} - The next item in the round-robin sequence.
 */
export function roundRobinSelectNext<T>(items: T[], currentIndex: number): T {
  const nextIndex = currentIndex + 1;
  return items[nextIndex % items.length];
}

/**
 *
 * @param {string} date date to compute the duration to
 * @returns a string representing the duration to the given date formatted as a human-readable string
 */
export function getDurationToPastDate(date: string) {
  // make sure we don't show future values in case of system time mismatch
  const safeValue = moment.min(moment(), moment(date));

  return moment().to(safeValue);
}
