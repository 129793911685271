import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

const customOrderStatusServiceState = builders.buildServiceState({
  requireEmployee: true,
  key: "Core:GetEnumValues:CustomOrderStatus",
  allowEmptyRequest: false,
  service: Core.GetEnumValues,
  defaultRequest: { Name: "CustomOrderStatus" },
});

export const customOrderStatusResultSelector = selector<
  EVA.Core.GetEnumValuesResponse.EnumValue[] | undefined
>({
  key: "Core:GetEnumValues:CustomOrderStatus",
  get: ({ get }) => get(customOrderStatusServiceState.response)?.Values,
});
