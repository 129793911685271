import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SearchListField } from "@new-black/lyra";

import { useEnumValuesQuery } from "../hooks/use-enum-values";

interface UserAccountTypeSearchListField {
  value?: number;
  onChange: (val?: number) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const UserAccountTypeSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: UserAccountTypeSearchListField) => {
  const intl = useIntl();

  const { isLoading, valuesArray: items } = useEnumValuesQuery("UserAccountType", false);

  const selectedValue = useMemo<EVA.Core.GetEnumValuesResponse.EnumValue | undefined>(
    () => items?.find((item) => item.Value === value),
    [items, value],
  );

  return (
    <SearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.Value}
      getLabel={(item) => item.Name}
      selectRenderElements={(item) => ({ label: item.Name })}
      value={selectedValue}
      items={items ?? []}
      isLoading={isLoading}
      onChange={(newValue) => onChange(newValue?.Value)}
      label={intl.formatMessage({
        id: "generic.label.account-type",
        defaultMessage: "Account type",
      })}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
    />
  );
};
