import { useMemo } from "react";

import { MultiSelect } from "@new-black/lyra";
import { useField, useFormikContext } from "formik";

import { useEnumValues } from "components/suite-composite/hooks/use-enum-values";

interface IGiftCardUsageTypesSelectProps {
  name?: string;
  label?: string;
  value: number | undefined;
  setValue: (newValue: number | undefined) => void;
  isRequired?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  hideHintLabel?: boolean;
}

export const GiftCardUsageTypesSelect = ({
  label,
  name,
  setValue,
  value,
  ...props
}: IGiftCardUsageTypesSelectProps) => {
  const { valuesArray: giftCardUsageTypes } = useEnumValues("GiftCardConfigurationUsageTypes");

  const selectItems = useMemo(
    () =>
      (giftCardUsageTypes ?? [])
        .filter((usageType) => usageType.Name !== "None")
        .map((usageType) => ({
          label: usageType.Name,
          value: `${usageType.Value}`,
        })),
    [giftCardUsageTypes],
  );

  const values = useMemo(
    () =>
      selectItems
        // eslint-disable-next-line no-bitwise
        .filter(
          (usageType) =>
            (Number(usageType.value) & (Number(value) ?? 0)) === Number(usageType.value),
        )
        .map((usageType) => usageType.value),
    [selectItems, value],
  );

  return giftCardUsageTypes ? (
    <>
      <MultiSelect
        {...props}
        label={label}
        items={selectItems}
        value={selectItems?.filter((item) => values.includes(`${item.value}`))}
        onChange={(newValue) => {
          const mapped = newValue?.map((val) => Number(val.value) ?? 0) ?? [];
          const val = mapped.reduce((acc, curr) => acc + curr, 0);
          console.log(val, newValue, val);
          setValue(val === 0 ? undefined : val);
        }}
        getItemId={(item) => item.value}
        getLabel={(item) => item.label}
        selectRenderElements={(item) => ({ label: item.label })}
      />
      {name && <input type="hidden" name={name} value={value} />}
    </>
  ) : null;
};

GiftCardUsageTypesSelect.defaultProps = {
  name: undefined,
  label: undefined,
  error: undefined,
  passive: undefined,
  disabled: undefined,
  required: undefined,
  helperText: undefined,
};

export const FormikGiftCardUsageTypesSelect = ({
  label,
  name,
  ...props
}: Omit<IGiftCardUsageTypesSelectProps, "value" | "setValue" | "error" | "helperText"> & {
  name: string;
}) => {
  const { setFieldValue } = useFormikContext();

  const [field, meta] = useField(name);

  return (
    <GiftCardUsageTypesSelect
      {...props}
      name={name}
      label={label}
      value={field.value}
      isInvalid={meta.touched && !!meta.error}
      errorMessage={meta.touched ? meta.error : undefined}
      setValue={(newValue) => setFieldValue(name, newValue)}
    />
  );
};
