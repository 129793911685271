export enum OrderProperties {
  None = 0,
  IsPickup = 1,
  CreatedByEmployee = 2,
  IsInterbranch = 4,
  IsB2B = 8,
  Duplicated = 16,
  /**
   * Indicates that the order was auto completed with force by a user.
   */
  Autocompleted = 32,
  /**
   * ReturnToSupplier indicates that the order will be returned from the store back to the supplier (RMA/CMA).
   */
  ReturnToSupplier = 128,
  /**
   * CustomTaxesApplied indicates that the order has custom taxes applied and other processes should not touch the taxes anymore.
   */
  CustomTaxesApplied = 256,
  /**
   * Replenishment indicates that this order is used to replenish a store/warehouse
   */
  Replenishment = 512,
  /**
   * Indicates that the SoldBy, which is normally determined automatically, has been manually overriden on the Order level.
   */
  SoldByOverridden = 1024,
  /**
   * Indicates that this order was created on a sentinel and pushed to the cloud.
   */
  ImportedFromLiveGuard = 2048,
  IsTransferOrder = 4096,
  IsReturnsTransferOrder = 8192,
  IsOffer = 16384,
  IsUnified = 32768,
  /**
   * Indicates that this order has been returned, partially or completely, by another order.
   */
  IsReturned = 65536,
  /**
   * Indicates the order contains lines that have negative quantities to ship, aka, are returned lines.
   */
  HasReturnLines = 131072,
  /**
   * Indicates that the order is a down payment order
   */
  DownPayment = 262144,
  /**
   * Indicates that the order is a restitution order
   */
  Restitution = 524288,
  Repairs = 1048576,
}
