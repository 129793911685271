import { FormattedMessage } from "react-intl";

import { Button, ButtonGroup } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";
import type { IProductMultiSearchModalProps, IProductSearchModalResultBase } from "../types";

interface IProductSearchModalRightPaneFooterProps<TProduct extends IProductSearchModalResultBase>
  extends Pick<
    IProductMultiSearchModalProps<TProduct>,
    | "messages"
    | "onSave"
    | "isSaveDisabled"
    | "isSaveLoading"
    | "saveButtonName"
    | "saveButtonValue"
    | "form"
    | "closeOnSave"
  > {
  onClose: () => void;
}

export function ProductSearchModalRightPaneFooter<TProduct extends IProductSearchModalResultBase>({
  closeOnSave,
  form,
  isSaveDisabled,
  isSaveLoading,
  messages,
  onClose,
  onSave,
  saveButtonName,
  saveButtonValue,
}: IProductSearchModalRightPaneFooterProps<TProduct>) {
  const { selectedProducts } = useProductSearchModalContext<TProduct>();

  return (
    <div className="flex justify-end">
      <ButtonGroup>
        <Button
          variant="secondary"
          onPress={() => {
            onClose();
          }}
        >
          <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          isDisabled={!selectedProducts.length || isSaveDisabled}
          onPress={() => {
            onSave?.(selectedProducts);
            if (closeOnSave) {
              onClose();
            }
          }}
          isLoading={isSaveLoading}
          name={saveButtonName}
          value={saveButtonValue}
          form={form}
          type={form ? "submit" : "button"}
        >
          {messages?.saveButton || (
            <FormattedMessage id="generic.label.save" defaultMessage="Save" />
          )}
        </Button>
      </ButtonGroup>
    </div>
  );
}
