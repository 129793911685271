import { useMemo } from "react";
import { useIntl } from "react-intl";

import { intlAccessor } from "util/intl-accessor";

export const formatDate = (date: string) => intlAccessor.formatDate(date);

export const useFormattedDate = (date: string) => {
  const intl = useIntl();
  const dateString = useMemo(() => intl.formatDate(date), [date, intl]);
  return dateString;
};

export const formatDateTime = (date: string) =>
  `${intlAccessor.formatDate(date)} ${intlAccessor.formatTime(date)}`;

export const useFormattedDateTime = (date: string) => {
  const intl = useIntl();
  const dateTimeString = useMemo(
    () => `${intl.formatDate(date)} ${intl.formatTime(date)}`,
    [date, intl],
  );
  return dateTimeString;
};
