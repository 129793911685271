import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const userTypesServiceState = builders.buildServiceState({
  key: "userTypes",
  service: Core.GetEnumValues,
  requireEmployee: true,
  allowEmptyRequest: false,
  defaultRequest: { Name: "UserTypes" },
});

export default userTypesServiceState;
