import { FormattedMessage, useIntl } from "react-intl";

import { CardContent, CardHeader, Separator } from "@new-black/lyra";

import LoadingStateBox from "components/shared/loading-state-box";
import Text from "components/suite-ui/text";

import { GridLeft, GridRight } from "./destination-helper-components";

interface IDestinationCustomer {
  organizationInfo?: EVA.Core.GetOrganizationUnitDetailedResponse;
  organizationInfoLoading?: boolean;
}
const DestinationOrganization = ({
  organizationInfo,
  organizationInfoLoading,
}: IDestinationCustomer) => {
  const intl = useIntl();

  if (!organizationInfo && organizationInfoLoading) {
    return <LoadingStateBox limit={5} headerAvailable />;
  }

  if (organizationInfo) {
    return (
      <>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.organization",
            defaultMessage: "Organization",
          })}
        />
        <CardContent>
          <div>
            <div className="flex justify-between">
              <GridLeft className="w-full lg:w-6/12 xl:w-5/12">
                <div className="flex justify-between">
                  <div className="mr-5">
                    <Text color="textSecondary">
                      <FormattedMessage id="generic.label.name" defaultMessage="Name" />
                    </Text>
                  </div>

                  <Text color="textPrimary" align="right">
                    {organizationInfo.Name}
                  </Text>
                </div>
                <div className="flex justify-between">
                  <div className="mr-5">
                    <Text color="textSecondary">
                      <FormattedMessage
                        id="generic.label.phone-number"
                        defaultMessage="Phone number"
                      />
                    </Text>
                  </div>

                  <Text color="textPrimary" align="right">
                    {organizationInfo.PhoneNumber}
                  </Text>
                </div>
              </GridLeft>
              <GridRight className="w-full lg:w-6/12 xl:w-5/12">
                <div className="flex justify-between">
                  <div className="mr-5">
                    <Text color="textSecondary">
                      <FormattedMessage
                        id="generic.label.email-address"
                        defaultMessage="Email address"
                      />
                    </Text>
                  </div>

                  <Text color="textPrimary" align="right">
                    {organizationInfo.EmailAddress}
                  </Text>
                </div>
              </GridRight>
            </div>
          </div>
          <Separator className="my-2" />
          <div>
            <div className="flex justify-between">
              <GridLeft className="w-full lg:w-6/12 xl:w-5/12">
                {organizationInfo.Address?.Address1 && (
                  <div className="flex justify-between">
                    <div className="mr-5">
                      <Text color="textSecondary">
                        <FormattedMessage id="generic.label.street" defaultMessage="Street" />
                      </Text>
                    </div>

                    <Text color="textPrimary" align="right">
                      {`${organizationInfo.Address.Address1} ${organizationInfo.Address.HouseNumber}`}
                    </Text>
                  </div>
                )}
                {organizationInfo.Address?.Address1 && (
                  <div className="flex justify-between">
                    <div className="mr-5">
                      <Text color="textSecondary">
                        <FormattedMessage
                          id="generic.label.address-1"
                          defaultMessage="Address line 1"
                        />
                      </Text>
                    </div>

                    <Text color="textPrimary" align="right">
                      {organizationInfo.Address.Address1}
                    </Text>
                  </div>
                )}
                {organizationInfo.Address?.Address2 && (
                  <div className="flex justify-between">
                    <div className="mr-5">
                      <Text color="textSecondary">
                        <FormattedMessage
                          id="generic.label.address-2"
                          defaultMessage="Address line 2"
                        />
                      </Text>
                    </div>

                    <Text color="textPrimary" align="right">
                      {organizationInfo.Address.Address2}
                    </Text>
                  </div>
                )}
                <div className="flex justify-between">
                  <div className="mr-5">
                    <Text color="textSecondary">
                      <FormattedMessage id="generic.label.city" defaultMessage="City" />
                    </Text>
                  </div>

                  <Text color="textPrimary" align="right">
                    {organizationInfo.Address?.City}
                  </Text>
                </div>
              </GridLeft>
              <GridRight className="w-full lg:w-6/12 xl:w-5/12">
                <div className="flex justify-between">
                  <div className="mr-5">
                    <Text color="textSecondary">
                      <FormattedMessage
                        id="generic.label.postal-code"
                        defaultMessage="Postal code"
                      />
                    </Text>
                  </div>

                  <Text color="textPrimary" align="right">
                    {organizationInfo.Address?.ZipCode}
                  </Text>
                </div>
                <div className="flex justify-between">
                  <div className="mr-5">
                    <Text color="textSecondary">
                      <FormattedMessage id="generic.label.country" defaultMessage="Country" />
                    </Text>
                  </div>

                  <Text color="textPrimary" align="right">
                    {organizationInfo.Address?.CountryID}
                  </Text>
                </div>
              </GridRight>
            </div>
          </div>
        </CardContent>
      </>
    );
  }

  return null;
};

export default DestinationOrganization;
