import moment from "moment";
import { z } from "zod";
import { zfd } from "zod-form-data";

import { intlAccessor } from "util/intl-accessor";

import { shouldEnableLoyaltyProgramLoyaltyPoints } from "../helpers";

export const enum LoyaltyProgramUserIdentifiers {
  RequestFromFrontEnd = "RequestFromFrontEnd",
  Custom = "Custom",
}

const userIdentifierSchema = z.enum([
  LoyaltyProgramUserIdentifiers.RequestFromFrontEnd,
  LoyaltyProgramUserIdentifiers.Custom,
]);

export const loyaltyProgramFormSchema = (
  isUserIdentifierRequired?: boolean,
  isHandlerRequired?: boolean,
) =>
  z
    .object({
      Name: z.string(),
      Description: z.string().optional(),
      Handler: isHandlerRequired ? z.string() : z.string().optional(),
      Options: zfd.numeric(),
      LoyaltyPointUsageOptions: zfd.numeric(z.number().optional()),
      RequiresSubscriptionValidation: zfd.checkbox(),
      Status: zfd.numeric(),
      StartDate: z.string().optional(),
      EndDate: z.string().optional(),
      LoyaltyProgramGroupID: zfd.numeric(z.number().optional()),
      UserIdentifier: isUserIdentifierRequired
        ? userIdentifierSchema
        : userIdentifierSchema.optional(),
      UserIdentifierPrefix: z.string().optional(),
      UserIdentifierStartNumber: zfd.numeric(z.number().optional()),
      UserIdentifierSuffix: z.string().optional(),
    })
    .refine(
      ({ EndDate, StartDate }) => {
        if (EndDate?.length && StartDate?.length) {
          return moment(EndDate).isSameOrAfter(StartDate);
        }

        return true;
      },
      {
        message: intlAccessor.formatMessage({
          id: "validation.start-date-before-end-date",
          defaultMessage: "Start date must be before end date.",
        }),
        path: ["StartDate"],
      },
    )
    .refine(
      ({ UserIdentifier, UserIdentifierStartNumber }) => {
        if (
          UserIdentifier === LoyaltyProgramUserIdentifiers.Custom &&
          UserIdentifierStartNumber === undefined
        ) {
          return false;
        }

        return true;
      },
      {
        message: intlAccessor.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required",
        }),
        path: ["UserIdentifierStartNumber"],
      },
    )
    .refine(
      ({ LoyaltyPointUsageOptions, Options }) => {
        if (
          shouldEnableLoyaltyProgramLoyaltyPoints(Options) &&
          LoyaltyPointUsageOptions === undefined
        ) {
          return false;
        }

        return true;
      },
      {
        message: intlAccessor.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required",
        }),
        path: ["LoyaltyPointUsageOptions"],
      },
    );

export const loyaltyProgramFormKeys = loyaltyProgramFormSchema()
  .innerType()
  .innerType()
  .innerType()
  .keyof().Values;

export type TLoyaltyProgramFormErrors = Partial<
  Record<keyof typeof loyaltyProgramFormKeys, string>
>;

export const enum LoyaltyProgramUsageTypes {
  None = 0,
  AllowAwarding = 1,
  UsableAsPaymentMethod = 2,
  UsableForWithdraw = 4,
}

export const enum LoyaltyProgramOptions {
  None = 0,
  EnablePaymentMethods = 1,
  EnableLoyaltyPoints = 2,
  EnableDiscounts = 4,
}

export enum LoyaltyProgramStatus {
  Inactive = 0,
  Active = 1,
}

export type TCustomPointPolicy = {
  IntervalType: number;
  IntervalValue: number;
};

export type TSetDatePointPolicy = {
  ExpirationDate: string;
  LeniencyDays?: number;
};

export type TParsedSetDatePointPolicy = {
  Day: number;
  Month: number;
  LeniencyDays?: number;
};
