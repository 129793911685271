import { useMemo } from "react";

import { Badge } from "@new-black/lyra";

import { useEnumValues } from "components/suite-composite/hooks/use-enum-values";
import Grid from "components/suite-ui/grid";

export function UserTypeCell({ value }: { value: number }) {
  const { valuesArray } = useEnumValues("UserTypes");

  const activeUserTypes = useMemo(
    () =>
      valuesArray
        ?.filter(
          // eslint-disable-next-line no-bitwise
          (userType) => (userType.Value & value) === userType.Value && userType.Name !== "None",
        )
        .map((userType) => ({
          ...userType,
          // Inserts a space before every capital letter,
          // and trims the spaces from the start and end of the string.
          // e.g.: "RemovedByRequest" -> replace -> " Removed By Request" -> trim -> "Removed By Request"
          DisplayName: userType.Name.replace(/([A-Z])/g, " $1").trim(),
        })),
    [value, valuesArray],
  );

  return (
    <div className="flex flex-wrap gap-2">
      {activeUserTypes?.map((userType) => (
        <Grid item key={`${value}-${userType.Value}`}>
          <Badge variant="transparent">{userType.DisplayName}</Badge>
        </Grid>
      ))}
    </div>
  );
}
