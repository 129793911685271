import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listOrdersLoaderQuery,
  serviceQuery: listOrdersQuery,
  serviceQueryKeys: listOrdersKeys,
  useServiceQueryHook: useListOrdersQuery,
  useServiceQueryWithRequest: useListOrdersQueryWithRequest,
} = createServiceQuery(Core.SearchOrders, true);

export const {
  serviceLoaderQuery: getOrderLoaderQuery,
  serviceQuery: getOrderQuery,
  serviceQueryKeys: getOrderKeys,
  useServiceQueryHook: useGetOrderQuery,
  useServiceQueryWithRequest: useGetOrderQueryWithRequest,
} = createServiceQuery(Core.GetOrder, true);

export const {
  serviceLoaderQuery: searchOrdersLoaderQuery,
  serviceQuery: searchOrdersQuery,
  serviceQueryKeys: searchOrdersKeys,
  useServiceQueryHook: useSearchOrdersQuery,
  useServiceQueryWithRequest: useSearchOrdersQueryWithRequest,
} = createServiceQuery(Core.SearchOrders, true);

export const {
  serviceLoaderQuery: listBlobsForOrderLoaderQuery,
  serviceQueryKeys: listBlobsForOrderQueryKeys,
  useServiceQueryHook: useListBlobsForOrderQuery,
} = createServiceQuery(Core.ListBlobsForOrder, true);

export const {
  serviceLoaderQuery: listOrderCustomTypesLoaderQuery,
  serviceQuery: listOrderCustomTypesQuery,
  serviceQueryKeys: listOrderCustomTypesQueryKeys,
  useServiceQueryHook: useListOrderCustomTypesQuery,
} = createServiceQuery(Core.ListOrderCustomTypes, true);

export const {
  serviceLoaderQuery: listOrderLinesLoaderQuery,
  serviceQuery: listOrderLinesQuery,
  serviceQueryKeys: listOrderLinesKeys,
  useServiceQueryHook: useListOrderLinesQuery,
} = createServiceQuery(Core.ListOrderLines, true, false);

export const {
  serviceLoaderQuery: getReturnableStatusForOrderLoaderQuery,
  serviceQuery: getReturnableStatusForOrderQuery,
  serviceQueryKeys: getReturnableStatusForOrderKeys,
  useServiceQueryHook: useGetReturnableStatusForOrderQuery,
} = createServiceQuery(Core.GetReturnableStatusForOrder, true);

export const {
  serviceLoaderQuery: getReturnOrdersForOrderLoaderQuery,
  serviceQuery: getReturnOrdersForOrderQuery,
  serviceQueryKeys: getReturnOrdersForOrderKeys,
  useServiceQueryHook: useGetReturnOrdersForOrderQuery,
} = createServiceQuery(Core.GetReturnOrdersForOrder, true);

export const {
  serviceLoaderQuery: getRelatedOrdersLoaderQuery,
  serviceQuery: getRelatedOrdersQuery,
  serviceQueryKeys: getRelatedOrdersKeys,
  useServiceQueryHook: useGetRelatedOrdersQuery,
} = createServiceQuery(Core.GetRelatedOrders, true);

export const { useServiceQueryHook: useGetOrderExportDocumentsQuery } = createServiceQuery(
  Core.GetOrderExportDocuments,
  true,
);

export const {
  serviceQuery: listOrdersForCustomerQuery,
  useServiceQueryHook: useListOrdersForCustomerQuery,
} = createServiceQuery(Core.ListOrdersForCustomer, true);
