import { useMemo } from "react";

import moment from "moment";

export interface IDeadlineTableCell {
  value: string;
}

const DeadlineTableCell = ({ value }: IDeadlineTableCell) => {
  const timeUntilDeadline = useMemo(() => {
    const deadline = moment(value);

    return moment().to(deadline);
  }, [value]);

  return <div>{timeUntilDeadline}</div>;
};

export default DeadlineTableCell;
