import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

const enumValuesServiceState = builders.buildServiceFamilyState({
  key: "UseEnumValues:Core.GetEnumValues",
  service: Core.GetEnumValues,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "string" || req?.Name === parameter) {
      return req;
    }
    return { ...req, Name: parameter };
  },
});

const responseSelector = selectorFamily({
  key: "UseEnumValues:Core.GetEnumValues:response",
  get:
    (param) =>
    ({ get }) =>
      get(enumValuesServiceState.response(param))?.Values,
});

export const enumValuesService = {
  response: responseSelector,
  serviceState: enumValuesServiceState,
};
