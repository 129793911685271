import { FormattedMessage } from "react-intl";

import { ConfirmationModal } from "components/suite-composite/confirmation-modal";

interface IDeclineTaskWarningDialogProps {
  open: boolean;
  onClose: (accept: boolean) => void;
  numberOfTasks?: number;
}

const DeclineTaskWarningDialog = ({
  numberOfTasks,
  onClose,
  open,
}: IDeclineTaskWarningDialogProps) => (
  <ConfirmationModal
    isOpen={open}
    onCancel={() => onClose(false)}
    onConfirm={() => onClose(true)}
    maxWidth="xs"
    messages={{
      confirmButtonMessage: (
        <FormattedMessage id="generic.label.decline" defaultMessage="Decline" />
      ),
      titleMessage: (
        <FormattedMessage id="decline-tasks.warning-dialog.title" defaultMessage="Decline tasks?" />
      ),
      descriptionMessage: (
        <FormattedMessage
          id="decline-tasks.warning-dialog.description"
          defaultMessage="Are you sure you want to decline {numberOfTasks} task(s)?"
          values={{ numberOfTasks: numberOfTasks ?? 0 }}
        />
      ),
    }}
  />
);

export default DeclineTaskWarningDialog;
