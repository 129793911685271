import { IntlShape } from "react-intl";

import { AddressFieldVisibilityType } from "types/address-field-visibility-type";

export const getAddressFieldVisibilityTypeTranslator = (intl: IntlShape) => {
  const translationMap: Record<AddressFieldVisibilityType, string> = {
    [AddressFieldVisibilityType.NotVisible]: intl.formatMessage({
      id: "generic.label.not-visible",
      defaultMessage: "Not visible",
    }),
    [AddressFieldVisibilityType.Visible]: intl.formatMessage({
      id: "generic.label.optional",
      defaultMessage: "Optional",
    }),
    [AddressFieldVisibilityType.VisibleAndRequired]: intl.formatMessage({
      id: "generic.label.required",
      defaultMessage: "Required",
    }),
  };

  return (visibilityType: AddressFieldVisibilityType) => {
    return translationMap[visibilityType];
  };
};
