import { Core } from "@springtree/eva-services-core";

import { getEnumValuesQuery } from "models/enum-values";
import { getEventExportTargetTypesQuery } from "models/event-exports";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateEventExportTargetSearchListField = () =>
  SearchListFieldGenerator<Core.GetEnumValues, { Name: string; Value: number }, number>({
    getItemId: (item) => item.Value,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) => resp?.Values,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.event-export-target",
      defaultMessage: "Event export target",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getEnumValuesQuery,
        initialRequest: { Name: "EventExportTarget" },
      }),
  });

export const generateEventExportTargetTypeSearchListField = (eventExportTargetID: number) =>
  SearchListFieldGenerator<Core.GetEventExportTargetTypes, { Name: string }, string>({
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) => resp?.EventTypes?.map((eventType) => ({ Name: eventType })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.event-type",
      defaultMessage: "Event type",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getEventExportTargetTypesQuery,
        initialRequest: { EventTarget: eventExportTargetID },
      }),
  });
