import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

const shipmentReceiveMethodsServiceState = builders.buildServiceState({
  requireEmployee: true,
  key: "Core:GetEnumValues:ShipmentReceiveMethods",
  allowEmptyRequest: false,
  service: Core.GetEnumValues,
  defaultRequest: { Name: "ShipmentReceiveMethods" },
});

export const shipmentReceiveMethodsResultSelector = selector<
  EVA.Core.GetEnumValuesResponse.EnumValue[] | undefined
>({
  key: "Core:GetEnumValues:ShipmentReceiveMethods",
  get: ({ get }) => get(shipmentReceiveMethodsServiceState.response)?.Values,
});
