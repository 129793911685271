import { Core } from "@springtree/eva-services-core";

import { getEnumValuesQuery } from "models/enum-values";
import { ONE_HOUR } from "util/base-values";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const EnumSearchListFieldGenerator = (
  enumName: string,
  defaultLabel?: string,
  excludeNone = true,
) =>
  SearchListFieldGenerator<Core.GetEnumValues, { Value: number; Name: string }, number>({
    getItemId: (item) => item.Value,
    defaultLabel,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) =>
      resp?.Values?.map((enumValue) => ({ Value: enumValue.Value, Name: enumValue.Name }))?.filter(
        (item) => (excludeNone ? item.Value !== 0 : true),
      ),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        staleTime: ONE_HOUR,
        refetchOnFocus: false,
        query: getEnumValuesQuery,
        initialRequest: { Name: enumName },
      }),
  });
