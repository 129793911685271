import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CardContent, FieldGroup, Radio, RadioGroup, TagInput, TextField } from "@new-black/lyra";

import { generateLyraProductSearchPropertySearchListField } from "../../generate-product-search-property-search-list-field";
import { ReducerStateProps } from "../product-filter-modal.types";

import { ProductFilterOptions } from "./product-filter-options";

const ProductSearchPropertySearchListField = generateLyraProductSearchPropertySearchListField({});

interface IProductFilterModalFormProps extends ReducerStateProps {
  isPropertyReadOnly?: boolean;
}

export function ProductFilterModalForm({
  dispatch,
  isPropertyReadOnly,
  state,
}: IProductFilterModalFormProps) {
  const intl = useIntl();

  const values = useMemo(
    () =>
      state.type === "values"
        ? state.Values?.map((value) => (typeof value === "string" ? value : value.toString())) ?? []
        : undefined,
    [state.Values, state.type],
  );

  return (
    <CardContent>
      <FieldGroup>
        <RadioGroup
          isRequired
          value={state.type}
          onChange={(newValue) =>
            dispatch({ type: "SET_TYPE", payload: newValue === "values" ? "values" : "range" })
          }
          label={intl.formatMessage({
            id: "generic.label.filter-type",
            defaultMessage: "Filter type",
          })}
        >
          <Radio value="values">
            <FormattedMessage id="generic.label.values" defaultMessage="Values" />
          </Radio>
          <Radio value="range">
            <FormattedMessage id="generic.label.range" defaultMessage="Range" />
          </Radio>
        </RadioGroup>

        <ProductSearchPropertySearchListField.SingleIDSearchListField.Controlled
          isRequired
          value={state.PropertyName}
          isDisabled={isPropertyReadOnly}
          isInvalid={!!state.errors?.PropertyName}
          errorMessage={state.errors?.PropertyName}
          onChange={(newValue) => dispatch({ type: "SET_PROPERTY", payload: newValue })}
          label={intl.formatMessage({ id: "generic.label.property", defaultMessage: "Property" })}
        />

        {state.type === "values" ? (
          <TagInput
            isRequired
            className="overflow-x-clip"
            value={values}
            isInvalid={!!state.errors?.Values}
            errorMessage={state.errors?.Values}
            onChange={(newValue) => dispatch({ type: "SET_VALUES", payload: newValue })}
            label={intl.formatMessage({
              id: "generic.label.value",
              defaultMessage: "Value",
            })}
          />
        ) : (
          <FieldGroup className="flex-row">
            <TextField
              isRequired
              value={state.From ?? ""}
              isInvalid={!!state.errors?.From}
              errorMessage={state.errors?.From}
              onChange={(newValue) => dispatch({ type: "SET_FROM", payload: newValue })}
              label={intl.formatMessage({ id: "generic.label.from", defaultMessage: "From" })}
            />
            <TextField
              isRequired
              value={state.To ?? ""}
              isInvalid={!!state.errors?.To}
              errorMessage={state.errors?.To}
              onChange={(newValue) => dispatch({ type: "SET_TO", payload: newValue })}
              label={intl.formatMessage({ id: "generic.label.to", defaultMessage: "To" })}
            />
          </FieldGroup>
        )}

        <ProductFilterOptions state={state} dispatch={dispatch} />
      </FieldGroup>
    </CardContent>
  );
}
