export interface IFiscalIDValidatedInputProps {
  /** Value(s) to be excluded from validation. */
  validationException?: string | string[];
  validationMessageVariant?: "warning" | "error";
  /** Callback fired after validation. */
  onAfterValidation?: (isAvailable?: boolean, fiscalId?: string) => void;
}

export type TFiscalIDValidatedInput<T> = T & IFiscalIDValidatedInputProps;

export enum AuditingFiscalIDValidationResult {
  Unknown = 0,
  Success = 1,
  Failure = -1,
}
