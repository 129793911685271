export default {
  module: {
    path: "/stock-management",
  },

  rts: {
    path: "/stock-management/rts",
    recalls: {
      overview: {
        path: "/stock-management/rts/recalls",
      },
      details: {
        path: "/stock-management/rts/recalls/:id",
      },
      edit: {
        path: "/stock-management/rts/recalls/:id/edit",
      },
      delete: {
        path: "/stock-management/rts/recalls/:id/delete",
      },
      process: {
        path: "/stock-management/rts/recalls/:id/process",
      },
      editSupplier: {
        path: "/stock-management/rts/recalls/:id/edit-supplier/:ouId",
      },
      new: {
        path: "/stock-management/rts/recalls/new",
      },
    },
    requestedReturns: {
      overview: {
        path: "/stock-management/rts/requested-returns",
      },
    },
  },
  purchaseOrders: {
    overview: {
      path: "/stock-management/purchase-orders",
      uploadInitialReplenishment: {
        path: "/stock-management/purchase-orders/upload-initial-replenishment",
      },
    },
    details: {
      path: "/stock-management/purchase-orders/:id",

      editGeneralInfo: {
        path: "/stock-management/purchase-orders/:id/edit-general-info",
      },

      cancelOrder: {
        path: "/stock-management/purchase-orders/:id/cancel-order",
      },
    },
    orderlineDetails: {
      path: "/stock-management/purchase-orders/:id/:orderlineid",
    },
  },
  orderFulfillments: {
    overview: {
      path: "/stock-management/order-fulfillments",
    },
    details: {
      path: "/stock-management/order-fulfillments/:id",

      shipFulfillment: {
        path: "/stock-management/order-fulfillments/:id/ship-fulfillment",
      },
      cancelOrderLineFulfillments: {
        path: "/stock-management/order-fulfillments/:id/cancel-order-line-fulfillments",
      },
    },
  },

  replenishments: {
    path: "/stock-management/replenishments",
    replenishmentProducts: {
      overview: {
        path: "/stock-management/replenishments/replenishment-products",
      },
      addMinMax: {
        path: "/stock-management/replenishments/replenishment-products/:id/add-min-max",
      },
      setMinMax: {
        path: "/stock-management/replenishments/replenishment-products/:id/set-min-max",
        grid: {
          path: "/stock-management/replenishments/replenishment-products/:id/grid/set-min-max",
        },
      },
      deleteProduct: {
        path: "/stock-management/replenishments/replenishment-products/:id/delete",
      },
      download: {
        path: "/stock-management/replenishments/replenishment-products/download",
      },
    },
    proposals: {
      overview: {
        path: "/stock-management/replenishments/proposals",
      },
      new: {
        path: "/stock-management/replenishments/proposals/new",
      },
      retry: {
        path: "/stock-management/replenishments/proposals/retry/:id",
      },
      details: {
        path: "/stock-management/replenishments/proposals/:id",
      },
    },
  },
  productCapacityPlans: {
    overview: {
      path: "/stock-management/product-capacity-plans",
    },
    details: {
      path: "/stock-management/product-capacity-plans/:id",
    },
  },

  allocation: {
    overview: {
      path: "/stock-management/allocation",
    },
    new: {
      path: "/stock-management/allocation/new",
    },
    details: {
      path: "/stock-management/allocation/:id",

      editGeneralInfo: {
        path: "/stock-management/allocation/:id/edit-general-info",
      },
    },
  },

  availability: {
    checkAvailability: {
      path: "/stock-management/availability",
    },
    results: {
      path: "/stock-management/availability/results",
      relatedOrders: {
        path: "/stock-management/availability/results/related-orders/:productId/:ouId",
      },
    },
  },

  overviewAndMutations: {
    path: "/stock-management/overview-and-mutations",
    stockOverview: {
      overview: {
        path: "/stock-management/overview-and-mutations/stock-overview",
        downloadOverview: {
          path: "/stock-management/overview-and-mutations/stock-overview/download-overview",
        },
        downloadFifoOverview: {
          path: "/stock-management/overview-and-mutations/stock-overview/download-fifo-overview",
        },
        relatedOrders: {
          path: "/stock-management/overview-and-mutations/stock-overview/related-orders/:productId/:ouId",
        },
        stockMutations: {
          path: "/stock-management/overview-and-mutations/stock-overview/stock-mutations/:productId/:ouId",
        },
        costPriceLedger: {
          path: "/stock-management/overview-and-mutations/stock-overview/cost-price-ledger/:productId/:ouId",
        },
      },
    },
    stockMutations: {
      overview: {
        path: "/stock-management/overview-and-mutations/stock-mutations",
        download: {
          path: "/stock-management/overview-and-mutations/stock-mutations/download",
        },
        upload: {
          path: "/stock-management/overview-and-mutations/stock-mutations/upload",
        },
      },
    },
  },

  shipping: {
    path: "/stock-management/shipping",
    carriers: {
      overview: {
        path: "/stock-management/shipping/carriers",
      },
      new: {
        path: "/stock-management/shipping/carriers/new",
      },
      edit: {
        path: "/stock-management/shipping/carriers/:id/edit",
      },
    },
    shippingMethodsAndCosts: {
      overview: {
        path: "/stock-management/shipping/shipping-methods-and-costs",
      },
      new: {
        path: "/stock-management/shipping/shipping-methods-and-costs/new",
      },
      details: {
        path: "/stock-management/shipping/shipping-methods-and-costs/:id",
        edit: {
          path: "/stock-management/shipping/shipping-methods-and-costs/:id/edit",
        },
        shippingCosts: {
          new: {
            path: "/stock-management/shipping/shipping-methods-and-costs/:id/shipping-costs/new",
          },
          edit: {
            path: "/stock-management/shipping/shipping-methods-and-costs/:id/shipping-costs/:shippingCostId/edit",
          },
          delete: {
            path: "/stock-management/shipping/shipping-methods-and-costs/:id/shipping-costs/:shippingCostId/delete",
          },
        },
      },
    },
    shippingRestrictions: {
      overview: {
        path: "/stock-management/shipping/shipping-restrictions",
      },
      new: {
        path: "/stock-management/shipping/shipping-restrictions/new",
      },
      details: {
        path: "/stock-management/shipping/shipping-restrictions/:id",
      },
      edit: {
        path: "/stock-management/shipping/shipping-restrictions/:id/edit",
      },
      delete: {
        path: "/stock-management/shipping/shipping-restrictions/:id/delete",
      },
    },
  },

  receiveGoods: {
    overview: {
      path: "/stock-management/receive-goods",
      fullyReceive: {
        path: "/stock-management/receive-goods/fully-receive/:id",
      },
      markAsUnreceived: {
        path: "/stock-management/receive-goods/mark-as-unreceived/:id",
      },
    },
    details: {
      path: "/stock-management/receive-goods/:id",
      editReceiveMethod: {
        path: "/stock-management/receive-goods/:id/edit-receive-method",
      },
      editReceivedGoods: {
        path: "/stock-management/receive-goods/:id/edit-received-goods",
      },
    },
  },
  stockLocations: {
    overview: {
      path: "/stock-management/stock-locations",
    },
    details: {
      path: "/stock-management/stock-locations/:id",
      edit: {
        path: "/stock-management/stock-locations/:id/edit",
      },
      setSettings: {
        path: "/stock-management/stock-locations/:id/set-settings/:ouId",
      },
      deleteSettings: {
        path: "/stock-management/stock-locations/:id/delete-settings/:ouId",
      },
      addSettings: {
        path: "/stock-management/stock-locations/:id/add-settings",
      },
    },
    create: {
      path: "/stock-management/stock-locations/new",
    },
  },
} as const;
